import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  completedStartScreenState,
  stateOfIntroductionState,
  userState,
} from "../atom/atoms";
import { surveyAtom, surveyCompletionState } from "../atom/survey/survey-atoms";
import { getNavigationItems } from "../store/pouch/navigation-pouch";
import "../input.css";
import "./Home.css";
import RandomGreeting from "./start/RandomGreeting";
import HomeMenu from "./start/menu/HomeMenu";

const Home = () => {
  const user = useRecoilValue(userState);
  const completedStartScreen = useRecoilValue(completedStartScreenState);
  const stateOfIntroduction = useRecoilValue(stateOfIntroductionState);
  const surveyCompletion = useRecoilValue(surveyCompletionState);
  const navigate = useNavigate();

  const handleClickMission = () => {
    navigate("/daily");
  };

  useEffect(() => {
    const processNavigationData = async () => {
      try {
        if (!completedStartScreen) {
          navigate(`/start`);
        } else if (!user) {
          navigate("/signup-login");
        } else if (!surveyCompletion && stateOfIntroduction !== "20") {
          navigate("/testchat");
        } else if (!surveyCompletion) {
          navigate("/survey");
        }
      } catch (error) {
        console.error("Error processing navigation items:", error);
      }
    };

    processNavigationData();
  }, [navigate, completedStartScreen, stateOfIntroduction, surveyCompletion]);

  return (
    <>
      <div className="flex flex-col h-screen">
        <>
          <div className="text-xl font-thin text-center text-blue-600 mb-4">
            <div>
              {
                <Typewriter
                  options={{
                    strings: [`Welcome to healthChat`],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    pauseFor: 99999,
                    cursor: "",
                  }}
                />
              }
            </div>
          </div>

          <div className="chatbot-image-container2">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="Chatbot"
              className="chatbot-image"
            />
          </div>
          <RandomGreeting />
        </>
        <HomeMenu />
      </div>
    </>
  );
};

export default Home;
