import React from "react";
import { useRecoilState } from "recoil";
import { tipsAtom } from "../../atom/tips/tips-atom";

// Define the FAQ component
const FAQ = () => {
  const [tipsState, setTipsState] = useRecoilState(tipsAtom);
  const faqForLogout = () => {
    setTipsState({ ...tipsState, isVisible: true });
  };
  return (
    <div className="flex flex-col items-center justify-center p-6">
      {/* FAQ Title */}
      <h1 className="text-blue-400 text-4xl font-extrabold mb-6 text-center">
        FAQ
      </h1>

      <img
        src="/chat2/07-chatbot-uni.png"
        alt="Chatbot University"
        className="mb-6"
      />

      <button
        onClick={faqForLogout}
        className="px-6 py-3 font-bold text-white bg-blue-400 rounded-lg shadow-lg shadow-blue-800/35"
      >
        How can I Logout?
      </button>
    </div>
  );
};

export default FAQ;
