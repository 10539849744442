import { formatDistanceToNow } from "date-fns";

const MealItem = ({ meal }) => {
  const {
    mealType,
    mealDateTime,
    mealAttachmentB64Array,
    mealCalorieProportion,
  } = meal;

  const formatTimeDistance = (dateTime) => {
    try {
      return formatDistanceToNow(new Date(dateTime), { addSuffix: true });
    } catch (error) {
      console.error("Error formatting date:", error);
      return dateTime; // Return original date if formatting fails
    }
  };

  return (
    <div className="flex items-center bg-white shadow-md rounded-lg overflow-hidden w-full max-w-md">
      <img
        src={mealAttachmentB64Array[0]}
        alt={mealType}
        className="w-1/3 h-auto object-cover"
      />
      <div className="p-4 w-2/3">
        <h3 className="text-lg font-semibold">{mealType}</h3>
        <p className="text-sm text-gray-600">
          {formatTimeDistance(mealDateTime)}
        </p>
        <p className="text-sm text-gray-600">
          {mealCalorieProportion} Calories
        </p>
      </div>
    </div>
  );
};

export default MealItem;
