import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const AppointmentBooking = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center p-4 bg-white shadow">
        <button
          onClick={handleBack}
          className="mr-4 text-blue-500 hover:text-blue-700"
        >
          <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        </button>
        <h2 className="text-2xl font-bold">Appointment Booking</h2>
      </div>
      
      <div className="flex-grow p-4">
        {/* Component content will go here */}
      </div>
    </div>
  );
};

export default AppointmentBooking;