import React from "react";
import "./SignupLogin.css";
import { userState } from "../../../atom/atoms";
import { useRecoilState } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { useNavigate } from "react-router-dom";
import { addUserObj } from "../../../store/pouch/user-pouch";
import { addSurvey, getSurvey } from "../../../store/pouch/survey-pouch";
import { addLevelUp } from "../../../store/pouch/level-up-pouch";

const SignupLogin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const handleTestLogin = () => {
    const awaitTestLogin = async () => {
      const testUserInfo = {
        userEmail: "test@dev.com",
        userName: "Test User",
        userNickName: null,
        userPhoneCountry: null,
        userPhone: null,
        userRoles: [],
      };
      debugger;
      setUser(testUserInfo);
      let activeUser = { _id: "active", ...testUserInfo };
      await addUserObj(activeUser);
      await addLevelUp(activeUser?.userEmail, 1, new Date().toISOString());
      let userSurveyProgress = await getSurvey(activeUser?.userEmail);
      if (!userSurveyProgress && !userSurveyProgress?.value) {
        userSurveyProgress = {
          currentQuestion: "100",
          isComplete: false,
          completionDateTime: null,
          earnedCoinAmount: 0,
          earnedCoinDatetime: null,
        };
      }
      await addSurvey({ _id: activeUser?.userEmail, userSurveyProgress, userCeatedDateTime: new Date().toISOString() });
      setCurrentQuestion("100");
      debugger;
      navigate("/");
    };
    awaitTestLogin();
  };
  const handleTestLogin2 = () => {
    navigate("/testcustom");
  };
  const handlePumpData = () => {
    // navigate("/pumpdata");
    navigate("/research-admin-offline");
  };

  return (
    <div className="mt-2 flex-col signup-login-container">
      {process.env.REACT_APP_ENABLE_ANY_API === "false" && (
        <>
          <div className="mb-4">
            <button className="test-login-button" onClick={handleTestLogin}>
              Test Login
            </button>
          </div>
          <div className="mb-4">
          <button className="test-login-button" onClick={handleTestLogin2}>
            Test Login 02
          </button>
          </div>
          <div className="mb-4">
          {/* button for pump data */}
            <button className="test-login-button" onClick={handlePumpData}>
              Test Research
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SignupLogin;
