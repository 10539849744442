import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

const calorieDB = new PouchDB("calorieDB");

// {
//   calorieId: `${user?.userEmail}-calorie-${new Date().toISOString()}`,
//   userEmail: user?.userEmail,
//   calorieType: "exercise",
//   calorieDescription: selectedWorkout,
//   calorieRemarks: "",
//   calorieDuration: parseInt(duration),
//   calorieChange,
//   calorieDateTime: new Date().toISOString(),
// };
calorieDB
  .createIndex({
    index: { fields: ["calorieDateTime"] },
  })
  .then(() => {
    console.log("Index created on calorieDateTime");
  })
  .catch((err) => {
    console.error("Error creating index:", err);
  });
calorieDB
  .createIndex({
    index: {
      fields: ["userEmail"],
    },
  })
  .then(() => {
    console.log("Compound index created on userEmail ");
  })
  .catch((err) => {
    console.error("Error creating email index:", err);
  });
calorieDB
  .createIndex({
    index: {
      fields: ["userEmail", "calorieDateTime"],
    },
  })
  .then(() => {
    console.log("Compound index created on userEmail and calorieDateTime");
  })
  .catch((err) => {
    console.error("Error creating compound index:", err);
  });
calorieDB
  .createIndex({
    index: {
      fields: ["userEmail", "calorieDateTime", "calorieType"],
    },
  })
  .then(() => {
    console.log(
      "Compound index created on userEmail, calorieDateTime, and calorieType",
    );
  })
  .catch((err) => {
    console.error("Error creating compound index:", err);
  });

calorieDB.viewCleanup();

export const getCaloriesByUserEmail = async (userEmail) => {
  try {
    let selector = {
      userEmail: userEmail,
      calorieDateTime: { $exists: true },
    };
    const result = await calorieDB.find({
      selector: selector,
      sort: [{ calorieDateTime: "desc" }],
    });

    console.log("Calories retrieved for user email:", result.docs);
    return result.docs;
  } catch (error) {
    debugger;
    console.error("Error retrieving calories for user email:", error);
    return [];
  }
};

export const getCaloriesByUserEmailAndDateTimeString = async (
  userEmail,
  startDateTime,
  endDateTime,
  calorieType = null,
) => {
  try {
    let selector = {
      userEmail: userEmail,
      calorieDateTime: {
        $gte: startDateTime,
        $lte: endDateTime,
      },
    };

    if (calorieType) selector.calorieType = calorieType;

    const result = await calorieDB.find({
      selector: selector,
      sort: [{ userEmail: "asc", calorieDateTime: "desc" }],
    });

    console.log("Calories retrieved for user within date range:", result.docs);
    return result.docs;
  } catch (error) {
    debugger;
    console.error(
      "Error retrieving calories for user within date range:",
      error,
    );
    return [];
  }
};

export const addCalorieItem = async (item) => {
  try {
    debugger;
    const existingItem = await calorieDB.get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await calorieDB.put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await calorieDB.get(item._id);
        item._rev = resolvedItem._rev;
        await calorieDB.put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};

export const getCalorieById = async (id) => {
  try {
    const byId = await calorieDB.get(id);
    console.log("Calorie byId retrieved:", byId);
    return byId;
  } catch (error) {
    if (error.status === 404) {
      console.warn("Calorie byId not found:", id);
      return null;
    }
    console.log("Error retrieving calorie:", error);
    return null;
  }
};

export const deleteCalorieItem = async (id) => {
  try {
    const item = await calorieDB.get(id);
    const response = await calorieDB.remove(item);
    return response;
  } catch (error) {
    console.error("Error deleting calorie item:", error);
    throw error;
  }
};

export default calorieDB;
