import { atom, selector } from "recoil";
import { localStorageEffect } from "../../util/localStorageEffect";

export const surveyAtom = atom({
  key: "surveyAtom",
  default: {
    currentQuestion: "0",
    isComplete: false,
    completionDateTime: null,
  },
  effects: [localStorageEffect("surveyAtom")],
});

export const currentQuestionState = selector({
  key: "currentQuestionState",
  get: ({ get }) => get(surveyAtom).currentQuestion,
  set: ({ set }, newValue) =>
    set(surveyAtom, (prevState) => ({
      ...prevState,
      currentQuestion: newValue,
    })),
});

export const surveyCompletionState = selector({
  key: "surveyCompletionState",
  get: ({ get }) => get(surveyAtom).isComplete,
  set: ({ set }, newValue) =>
    set(surveyAtom, (prevState) => ({
      ...prevState,
      isComplete: newValue,
      completionDateTime: newValue ? new Date().toISOString() : null,
    })),
});
