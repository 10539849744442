import { atom } from "recoil";
import { localStorageEffect } from "../../util/localStorageEffect";

export const dailyAtom = atom({
  key: "dailyAtom",
  default: {
    lastDailyWeighingDateTime: null,
    daysSinceJoined: 0,
    todaysWeight: null,
  },
  effects: [localStorageEffect("dailyAtom")],
});

export const clearDailyAtom = ({ set }) => {
  try {
    set({
      lastDailyWeighingDateTime: null,
      daysSinceJoined: 0,
      todaysWeight: null,
    });
  } catch (error) {
    console.log(error);
  }
  // localStorage.removeItem("dailyAtom");
};
