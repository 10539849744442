import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

const chatDB = new PouchDB("chatDB");
chatDB
  .createIndex({
    index: { fields: ["chatDate"] },
  })
  .then(() => {
    console.log("Index created on chatDate");
  })
  .catch((err) => {
    console.error("Error creating index:", err);
  });
chatDB
  .createIndex({
    index: {
      fields: ["userEmail"],
    },
  })
  .then(() => {
    console.log("Compound index created on userEmail ");
  })
  .catch((err) => {
    console.error("Error creating email index:", err);
  });
chatDB
  .createIndex({
    index: {
      fields: ["userEmail", "chatDateTime"],
    },
  })
  .then(() => {
    console.log("Compound index created on userEmail and chatDateTime");
  })
  .catch((err) => {
    console.error("Error creating compound index:", err);
  });
chatDB
  .createIndex({
    index: {
      fields: ["userEmail", "initiatingParty", "chatDateTime"],
    },
  })
  .then(() => {
    console.log(
      "Compound index created on userEmail initiatingParty, and chatDateTime",
    );
  })
  .catch((err) => {
    console.error("Error creating compound index:", err);
  });

chatDB
  .createIndex({
    index: {
      fields: ["userEmail", "chatTopicId"],
    },
  })
  .then()
  .catch((err) => {
    debugger;
  });

chatDB
  .createIndex({
    index: {
      fields: ["userEmail", "initiatingParty", "chatTopicId", "chatDateTime"],
    },
  })
  .then()
  .catch((err) => {
    debugger;
  });

chatDB
  .createIndex({
    index: {
      fields: [
        "userEmail",
        "chatText",
        "chatTopic",
        "chatTopicId",
        "chatTopicDateTime",
      ],
    },
  })
  .then()
  .catch((err) => {
    debugger;
  });

chatDB
  .createIndex({
    index: {
      fields: ["userEmail", "chatTopic", "chatTopicId", "chatTopicDateTime"],
    },
  })
  .then()
  .catch((err) => {
    debugger;
  });

chatDB
  .createIndex({
    index: {
      fields: [
        "userEmail",
        "initiatingParty",
        "chatTopicId",
        "chatDateTime",
        "chatText",
      ],
    },
  })
  .then()
  .catch((err) => {
    debugger;
  });

chatDB
  .createIndex({
    index: {
      fields: [
        "userEmail",
        "initiatingParty",
        "chatTopicId",
        "chatDate",
        "chatDateTime",
        "chatText",
      ],
    },
  })
  .then()
  .catch((err) => {
    debugger;
  });

chatDB.viewCleanup();

export const getChatsByUserEmail = async (userEmail) => {
  try {
    let selector = {
      userEmail: userEmail,
      chatDateTime: { $exists: true },
    };
    const result = await chatDB.find({
      selector: selector,
      sort: [{ chatDateTime: "desc" }],
    });
    return result.docs;
  } catch (error) {
    return [];
  }
};

export const getChatsByUserEmailAndTopicId = async (userEmail, chatTopicId) => {
  try {
    let selector = {
      userEmail: userEmail,
      initiatingParty: { $exists: true },
      chatTopicId: chatTopicId,
      chatDateTime: { $exists: true },
      chatText: { $exists: true },
    };
    const result = await chatDB.find({
      selector: selector,
      sort: [
        { userEmail: "asc" },
        { initiatingParty: "asc" },
        { chatTopicId: "asc" },
        { chatDateTime: "desc" },
        { chatText: "asc" },
      ],
    });
    return result.docs;
  } catch (error) {
    return [];
  }
};

export const getChatsByUserEmailAndDateTimeString = async (
  userEmail,
  startDateTime,
  endDateTime,
  chatType = null,
) => {
  try {
    let selector = {
      userEmail: userEmail,
      chatDateTime: {
        $gte: startDateTime,
        $lte: endDateTime,
      },
    };

    if (chatType) selector.chatType = chatType;

    const result = await chatDB.find({
      selector: selector,
      sort: [{ userEmail: "asc" }, { chatDateTime: "desc" }],
    });
    return result.docs;
  } catch (error) {
    console.error("Error retrieving chats for user within date range:", error);
    return [];
  }
};

export const addChatItem = async (item) => {
  try {
    const existingItem = await chatDB.get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await chatDB.put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await chatDB.get(item._id);
        item._rev = resolvedItem._rev;
        await chatDB.put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};

export const getChatById = async (id) => {
  try {
    const byId = await chatDB.get(id);
    console.log("Chat byId retrieved:", byId);
    return byId;
  } catch (error) {
    if (error.status === 404) {
      console.warn("Chat byId not found:", id);
      return null;
    }
    console.log("Error retrieving chat:", error);
    return null;
  }
};

export const getUniqueChatTopics = async (userEmail) => {
  try {
    const result = await chatDB.find({
      selector: {
        userEmail: userEmail,
        chatTopic: { $exists: true },
        chatTopicId: { $exists: true },
        chatTopicDateTime: { $exists: true },
      },
      fields: ["userEmail", "chatTopicDateTime", "chatTopicId", "chatTopic"],
      sort: [
        { userEmail: "asc" },
        { chatTopic: "asc" },
        { chatTopicId: "asc" },
        { chatTopicDateTime: "desc" },
      ],
    });

    // Create a map to ensure uniqueness based on chatTopicId
    const uniqueTopicsMap = result.docs.reduce((map, doc) => {
      if (!map.has(doc.chatTopicId)) {
        map.set(doc.chatTopicId, {
          chatTopicId: doc.chatTopicId,
          chatTopic: doc.chatTopic,
        });
      }
      return map;
    }, new Map());

    // Convert the map values to an array
    const uniqueTopics = Array.from(uniqueTopicsMap.values());

    return uniqueTopics;
  } catch (err) {
    console.error("Error fetching unique chat topics:", err);
    throw err;
  }
};

export const deleteChatItem = async (id) => {
  try {
    const item = await chatDB.get(id);
    const response = await chatDB.remove(item);
    return response;
  } catch (error) {
    console.error("Error deleting chat item:", error);
    throw error;
  }
};

export default chatDB;
