import { differenceInDays, parseISO } from 'date-fns';
import { toZonedTime as utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

const MALAYSIA_TIMEZONE = 'Asia/Kuala_Lumpur';

export const countProgramDay = (userProgramStartDateTime) => {
  if (!userProgramStartDateTime) {
    return 0;
  }

  const startDate = utcToZonedTime(parseISO(userProgramStartDateTime), MALAYSIA_TIMEZONE);
  const currentDate = utcToZonedTime(new Date(), MALAYSIA_TIMEZONE);

  const daysDifference = differenceInDays(currentDate, startDate);
  return daysDifference + 1;
};
