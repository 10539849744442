import PouchDB from "pouchdb";

export const userDB = new PouchDB("userDB");

export const addUserObj = async (userObj) => {
  try {
    const response = await userDB.put(userObj);
    return response;
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const existingUser = await userDB.get(userObj._id);
        userObj._rev = existingUser._rev;
        const response = await userDB.put(userObj);
        return response;
      } catch (retryError) {
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};

export const getUserObj = async (id) => {
  try {
    const userObj = await userDB.get(id);
    return userObj;
  } catch (error) {
    if (error.status === 404) {
      return null;
    }
    throw error;
  }
};

export const deleteUserObj = async (id) => {
  try {
    const userObj = await userDB.get(id);
    const response = await userDB.remove(userObj);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateUserInitialWeight = async (userEmail, initialWeight) => {
  try {
    const userObj = await userDB.get(userEmail);
    userObj.userInitialWeightKg = initialWeight;
    const response = await userDB.put(userObj);
    return response;
  } catch (error) {
    console.error("Error updating user initial weight:", error);
    throw error;
  }
};

// updateUserGender
export const updateUserGender = async (userEmail, gender) => {
  try {
    const userObj = await userDB.get(userEmail);
    userObj.userGender = gender;
    const response = await userDB.put(userObj);
    return response;
  } catch (error) {
    console.error("Error updating user gender:", error);
    throw error;
  }
};

// updateUserHeight
export const updateUserHeight = async (userEmail, height) => {
  try {
    const userObj = await userDB.get(userEmail);
    userObj.userHeightCm = height;
    const response = await userDB.put(userObj);
    return response;
  } catch (error) {
    console.error("Error updating user height:", error);
    throw error;
  }
};

// updateUserAge
export const updateUserAgeGroup = async (userEmail, ageGroup) => {
  try {
    const userObj = await userDB.get(userEmail);
    userObj.userAgeGroup = ageGroup;
    const response = await userDB.put(userObj);
    return response;
  } catch (error) {
    console.error("Error updating user age group:", error);
    throw error;
  }
};

//updateUserEthnicity
export const updateUserEthnicity = async (userEmail, ethnicity) => {
  try {
    const userObj = await userDB.get(userEmail);
    userObj.userEthnicity = ethnicity;
    const response = await userDB.put(userObj);
    return response;
  } catch (error) {
    console.error("Error updating user ethnicity:", error);
    throw error;
  }
};


//updateUserBMI
export const updateUserBMI = async (userEmail, bmi) => {
  try {
    const userObj = await userDB.get(userEmail);
    userObj.userBMI = bmi;
    const response = await userDB.put(userObj);
    return response;
  } catch (error) {
    if (error.name === 'not_found') {
      // If the document doesn't exist, create a new one
      const newUser = {
        _id: userEmail,
        userEmail: userEmail,
        userBMI: bmi
      };
      const response = await userDB.put(newUser);
      return response;
    } else {
      // If it's any other error, log and rethrow
      console.error("Error updating user BMI:", error);
      throw error;
    }
  }
};

// Add this new function to update user phone and country code
export const updateUserPhone = async (userEmail, phoneNumber, countryCode) => {
  try {
    const userObj = await userDB.get(userEmail);
    userObj.userPhone = phoneNumber;
    userObj.userPhoneCountry = countryCode;
    const response = await userDB.put(userObj);
    return response;
  } catch (error) {
    if (error.name === 'not_found') {
      // If the document doesn't exist, create a new one
      const newUser = {
        _id: userEmail,
        userEmail: userEmail,
        userPhone: phoneNumber,
        userPhoneCountry: countryCode
      };
      const response = await userDB.put(newUser);
      return response;
    } else {
      // If it's any other error, log and rethrow
      console.error("Error updating user phone and country code:", error);
      throw error;
    }
  }
};

// Update user waist with upsert functionality
export const updateUserWaist = async (userEmail, waist) => {
  try {
    const userObj = await userDB.get(userEmail);
    userObj.userWaistInch = waist;
    const response = await userDB.put(userObj);
    return response;
  } catch (error) {
    if (error.name === 'not_found') {
      // If the document doesn't exist, create a new one
      const newUser = {
        _id: userEmail,
        userEmail: userEmail,
        userWaistInch: waist
      };
      const response = await userDB.put(newUser);
      return response;
    } else {
      // If it's any other error, log and rethrow
      console.error("Error updating user waist:", error);
      throw error;
    }
  }
};

// Add this new function to update user nickname
export const updateUserNickname = async (userEmail, nickname) => {
  try {
    // First, try to get the existing user document
    const existingUser = await userDB.get(userEmail);
    
    // If the document exists, update the nickname
    existingUser.userNickName = nickname;
    const response = await userDB.put(existingUser);
    return response;
  } catch (error) {
    if (error.name === 'not_found') {
      // If the document doesn't exist, create a new one
      const newUser = {
        _id: userEmail,
        userEmail: userEmail,
        userNickName: nickname
      };
      const response = await userDB.put(newUser);
      return response;
    } else {
      // If it's any other error, log and rethrow
      console.error("Error updating user nickname:", error);
      throw error;
    }
  }
};

export const getAllUsersExceptActive = async (activeUserEmail) => {
  try {
    const allDocs = await userDB.allDocs({
      include_docs: true,
      attachments: false
    });

    const users = allDocs.rows
      .map(row => row.doc)
      .filter(doc => doc._id !== activeUserEmail && doc._id.includes('@'));

    return users;
  } catch (error) {
    console.error("Error fetching all users:", error);
    throw error;
  }
};

