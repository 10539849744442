import { atom, selector, selectorFamily } from "recoil";
import { saveSurveyDataToPDB } from "../../store/pouch/survey-questions-pouch";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
export const clearSurveyQuestionsAtom = ({ set }) => {
  try {
    set({
      survey_data: [],
    });
  } catch (error) {
    console.log(error);
  }
  // localStorage.removeItem("surveyQuestionsAtom");
};
export const surveyQuestionsAtom = atom({
  key: "surveyQuestionsAtom",
  default: {
    survey_data: [],
  },
  effects: [localStorageEffect("surveyQuestionsAtom")],
});

export const surveyDataState = selector({
  key: "surveyDataState",
  get: ({ get }) => get(surveyQuestionsAtom).survey_data,
  set: ({ set }, newValue) =>
    set(surveyQuestionsAtom, (prevState) => ({
      ...prevState,
      survey_data: newValue,
    })),
});

export const questionAnswerState = selectorFamily({
  key: "questionAnswerState",
  get:
    (questionNumber) =>
    ({ get }) => {
      const surveyData = get(surveyQuestionsAtom).survey_data;
      return (
        surveyData.find((q) => q?.surveyQuestionNumber === questionNumber) ||
        null
      );
    },
  set:
    (questionNumber) =>
    ({ set }, newValue) => {
      set(surveyQuestionsAtom, (prevState) => {
        const newSurveyData = prevState.survey_data.map((q) =>
          q?.surveyQuestionNumber === questionNumber ? newValue : q,
        );
        if (
          !newSurveyData.some((q) => q?.surveyQuestionNumber === questionNumber)
        ) {
          newSurveyData.push(newValue);
        }
        return { ...prevState, survey_data: newSurveyData };
      });
    },
});
