import React from "react";
import { IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BottomNavButton = ({ icon, label, onClick, disabled }) => (
  <div
    onClick={disabled ? undefined : onClick}
    className={`flex flex-column ${
      disabled ? "opacity-50 cursor-not-allowed" : ""
    }`}
    style={{
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      padding: "8px 0",
    }}
  >
    <IconButton color="inherit" size="small" style={{ padding: "8px" }}>
      <FontAwesomeIcon icon={icon} size="lg" />
    </IconButton>
    <Typography
      variant="caption"
      style={{
        marginTop: "4px",
        fontSize: "0.7rem",
        textAlign: "center",
      }}
    >
      {label}
    </Typography>
  </div>
);

export default BottomNavButton;
