import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

const weightDB = new PouchDB("weightDB");
weightDB
  .createIndex({
    index: { fields: ["weightTxDateTime"] },
  })
  .then(() => {
    console.log("Index created on weightTxDateTime");
  })
  .catch((err) => {
    console.error("Error creating index:", err);
  });
weightDB
  .createIndex({
    index: {
      fields: ["userEmail", "weightTxDateTime"],
    },
  })
  .then(() => {
    console.log("Compound index created on userEmail and weightTxDateTime");
  })
  .catch((err) => {
    console.error("Error creating compound index:", err);
  });

export const getWeightByUserEmailAndDateTimeString = async (
  userEmail,
  startDateTime,
  endDateTime,
) => {
  try {
    const result = await weightDB.find({
      selector: {
        userEmail: userEmail,
        weightTxDateTime: {
          $gte: startDateTime,
          $lte: endDateTime,
        },
      },
      sort: [{ weightTxDateTime: "desc" }],
    });

    console.log("Weights retrieved for user within date range:", result.docs);
    return result.docs;
  } catch (error) {
    console.error(
      "Error retrieving weights for user within date range:",
      error,
    );
    return [];
  }
};

export const addWeightItem = async (item) => {
  try {
    const itemWithStringDates = {
      ...item,
      weightTxDateTime:
        item.weightTxDateTime instanceof Date
          ? item.weightTxDateTime.toISOString()
          : item.weightTxDateTime,
    };
    item = itemWithStringDates;
    const existingItem = await weightDB.get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await weightDB.put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await weightDB.get(item._id);
        item._rev = resolvedItem._rev;
        await weightDB.put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};

export const getWeightById = async (id) => {
  try {
    const byId = await weightDB.get(id);
    console.log("Weight byId retrieved:", byId);
    return byId;
  } catch (error) {
    if (error.status === 404) {
      console.warn("Weight byId not found:", id);
      return null;
    }
    console.log("Error retrieving weight:", error);
    return null;
  }
};

export const deleteWeightItem = async (id) => {
  try {
    const item = await weightDB.get(id);
    const response = await weightDB.remove(item);
    return response;
  } catch (error) {
    console.error("Error deleting weight item:", error);
    throw error;
  }
};

export default weightDB;
