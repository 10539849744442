import React, { useEffect, useState } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Home from "./component/Home";
import Survey from "./component/survey/Survey";
import Start from "./component/start/Start";
import Privacy from "./component/start/Privacy";
import Terms from "./component/start/Terms";
import Translate from "./translate/Translate";
import TestChat from "./component/chat/TestChat";
import ResearchAdmin from "./component/research-admin/ResearchAdmin";
import Store from "./store/Store";
import { UserProvider, useUser } from "./store/UserContext";
import { jwtDecode } from "jwt-decode";
import UserLogoutMenu from "./component/avatar/UserLogoutMenu";
import { fetchGoogleToken, fetchMetaToken } from "./api/ApiService";
import UserAvatarButton from "./component/avatar/UserAvatarButton";
import DailyMission from "./component/mission/DailyMission";
import UserLoginMenu from "./component/avatar/UserLoginMenu";
import FeaturesMenu from "./component/avatar/FeaturesMenu";

// import { UseApi } from "./api/UseApi";
import DailyLifestyle from "./component/mission/DailyLifestyle-02";
import MyProfile from "./component/profile/MyProfile";
import { setProfileModalOpen } from "./store/Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faHome,
  faPlay,
  faTrophy,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import DailyWeigh from "./component/mission/sub/Daily-01-Weigh";
import EarnedCoin from "./component/mission/EarnedCoin";
import { Context00RootProvider } from "./context/Context00Root";
import { RecoilRoot } from "recoil";
import WeightHistory from "./component/mission/fatloss/WeightHistory";
import DailyPredictFatLoss from "./component/mission/sub/Daily-Predict-FatLoss";
import DailyHabits from "./component/mission/sub/Daily-03-Habits";
import Daily04Workout from "./component/mission/sub/Daily-04-Workout";
import DailyMore from "./component/mission/DailyMore";
import Chatbot01 from "./component/chat/Chatbot01";
import MealSnap from "./component/meal/MealSnap";
import MealGallery from "./component/meal/MealGallery";
import NextLevel from "./component/mission/level/NextLevel";
import CustomAppBar from "./component/avatar/CustomAppBar";
import SignupLogin from "./component/avatar/signup-login/signup-login";
import Logout from "./component/avatar/signup-login/logout";
import Tips from "./component/chat/Tips";

import TestCustomUser from "./component/avatar/signup-login/ztest-custom-user";
import CalorieHistory from "./component/mission/fatloss/CalorieHistory";
import MealCrop from "./component/meal/MealCrop";
import PumpData from "./component/avatar/signup-login/zpump-data";
import ResearchAdminOffline from "./component/research-admin/ResearchAdminOffline";
import AppointmentOffline from "./component/research-admin/appointment/AppointmentOffline";
import AppointmentBooking from "./component/mission/appointment/AppointmentBooking";
import UpLevelMedal from "./component/mission/UpLevelMedal";
import TrackBMI from "./component/mission/sub/TrackBMI";

const App = () => {
  const [recheckIsCompleteSurvey, setRecheckIsCompleteSurvey] = useState(0);
  const [isAllowedBackHome, setIsAllowedBackHome] = useState(false);
  const [playEarnCoin, setPlayEarnCoin] = useState(false);
  const [coinsEarned, setCoinsEarned] = useState(0);

  const pathname = window.location.pathname;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div style={{ flex: 1 }}>
        <Tips />
        <EarnedCoin />
        <UpLevelMedal />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/start" element={<Start />} />
          <Route
            path="/survey"
            element={
              <Survey
                setIsAllowedBackHome={setIsAllowedBackHome}
                recheckIsCompleteSurvey={recheckIsCompleteSurvey}
              />
            }
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route
            path="/terms"
            element={<Terms setIsAllowedBackHome={setIsAllowedBackHome} />}
          />
          <Route path="/translate" element={<Translate />} />
          <Route path="/daily" element={<DailyMission />} />
          <Route path="/lifestyle" element={<DailyLifestyle />} />
          <Route
            path="/testchat"
            element={
              /* api={api} */
              <TestChat setIsAllowedBackHome={setIsAllowedBackHome} />
            }
          />
          <Route path="/research-admin" element={<ResearchAdmin />} />
          <Route path="/research-admin-offline" element={<ResearchAdminOffline />} />
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/weighing" element={<DailyWeigh />} />
          <Route path="/weight-history" element={<WeightHistory />} />
          <Route path="/fat-loss" element={<DailyPredictFatLoss />} />
          <Route path="/assess-habits" element={<DailyHabits />} />
          <Route path="/exercise" element={<Daily04Workout />} />
          <Route path="/daily-more" element={<DailyMore />} />
          <Route path="/chatbot" element={<Chatbot01 />} />
          <Route path="/mealsnap" element={<MealSnap />} />
          <Route path="/mealcrop" element={<MealCrop />} />
          <Route path="/gallery" element={<MealGallery />} />
          <Route path="/next-level" element={<NextLevel />} />
          <Route path="/signup-login" element={<SignupLogin />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/testcustom" element={<TestCustomUser />} />
          <Route path="/calorie" element={<CalorieHistory />} />
          <Route path="/pumpdata" element={<PumpData />} />
          <Route path="/appointment-offline" element={<AppointmentOffline />} />
          <Route path="/appointment-booking" element={<AppointmentBooking />} />
          <Route path="/track-bmi" element={<TrackBMI />} />
        </Routes>
      </div>
      <CustomAppBar />
    </div>
  );
};

const AppWrapper = () => (
  <Provider store={Store}>
    <Router>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Router>
  </Provider>
);

export default AppWrapper;
