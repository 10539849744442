import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { startOfWeek, addDays, format, setHours, setMinutes, addHours, parseISO, addWeeks, isSameWeek, isBefore, set } from 'date-fns';
import { toZonedTime as utcToZonedTime, toDate } from 'date-fns-tz';

const AppointmentOffline = () => {
  const navigate = useNavigate();
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [weekDays, setWeekDays] = useState([]);
  const timeZone = 'Asia/Kuala_Lumpur';

  useEffect(() => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(addDays(currentWeekStart, i));
    }
    setWeekDays(days);
  }, [currentWeekStart]);

  const generateTimeSlots = () => {
    const slots = [];
    const startTime = setHours(setMinutes(new Date(), 0), 8);
    const endTime = setHours(setMinutes(new Date(), 0), 23);

    for (let time = startTime; time <= endTime; time = addHours(time, 1)) {
      slots.push(format(time, 'HH:mm'));
    }

    return slots;
  };

  const timeSlots = generateTimeSlots();

  const handleSlotClick = (day, time) => {
    const dayOfWeek = format(day, 'EEEE');
    const slotDateTime = set(day, { hours: parseInt(time.split(':')[0]), minutes: parseInt(time.split(':')[1]) });
    
    if (dayOfWeek !== 'Tuesday' && dayOfWeek !== 'Thursday' && !isPastSlot(slotDateTime)) {
      const slotKey = `${format(day, 'yyyy-MM-dd')}_${time}`;
      setSelectedSlots(prev => 
        prev.includes(slotKey) ? prev.filter(slot => slot !== slotKey) : [...prev, slotKey]
      );
    }
  };

  const isPastSlot = (slotDateTime) => {
    const now = new Date();
    return isBefore(slotDateTime, now);
  };

  const saveAppointmentSlots = () => {
    const appointmentSlots = selectedSlots.map(slot => {
      const [date, time] = slot.split('_');
      const localDateTime = parseISO(`${date}T${time}:00`);
      const zonedDateTime = utcToZonedTime(localDateTime, timeZone);
      const utcDateTime = toDate(zonedDateTime, { timeZone: 'UTC' });
      return utcDateTime.toISOString();
    });

    console.log('Appointment slots saved:', appointmentSlots);
    // Here you would typically save these to your database
  };

  const goToNextWeek = () => {
    setCurrentWeekStart(addWeeks(currentWeekStart, 1));
  };

  const goToCurrentWeek = () => {
    setCurrentWeekStart(startOfWeek(new Date(), { weekStartsOn: 1 }));
  };

  const isCurrentWeek = isSameWeek(currentWeekStart, new Date(), { weekStartsOn: 1 });

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-center p-4 bg-white shadow">
        <button
          onClick={handleBack}
          className="mr-4 text-blue-500 hover:text-blue-700"
        >
          <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        </button>
        <h2 className="text-2xl text-blue-400 font-bold">Appointment Slot Config</h2>
      </div>
      
      <div className="flex-grow overflow-auto p-4">
        <div className="flex justify-between mb-4">
          <div>
            <span className="font-bold">Week of: </span>
            {format(currentWeekStart, 'dd MMM yyyy')}
          </div>
          <div>
            {!isCurrentWeek && (
              <button
                onClick={goToCurrentWeek}
                className="mr-2 px-2 py-1 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >
                <FontAwesomeIcon icon={faCalendarDay} className="mr-1" />
                Current Week
              </button>
            )}
            <button
              onClick={goToNextWeek}
              className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Next Week
              <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
            </button>
          </div>
        </div>
        
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="p-2 border"></th>
                {weekDays.map(day => {
                  const dayName = format(day, 'EEE');
                  if (dayName === 'Tue' || dayName === 'Thu') {
                    return (
                      <th key={day} className="p-2 border bg-pink-200 w-12">
                        {dayName}<br />N/A
                      </th>
                    );
                  }
                  return (
                    <th key={day} className="p-2 border">
                      {format(day, 'EEE dd/MM')}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {timeSlots.map(time => (
                <tr key={time}>
                  <td className="p-2 border">{time}</td>
                  {weekDays.map(day => {
                    const dayName = format(day, 'EEE');
                    const slotKey = `${format(day, 'yyyy-MM-dd')}_${time}`;
                    const slotDateTime = set(day, { hours: parseInt(time.split(':')[0]), minutes: parseInt(time.split(':')[1]) });
                    const isPast = isPastSlot(slotDateTime);

                    if (dayName === 'Tue' || dayName === 'Thu') {
                      return (
                        <td
                          key={`${day}_${time}`}
                          className="p-2 border bg-pink-200"
                        ></td>
                      );
                    }
                    return (
                      <td
                        key={`${day}_${time}`}
                        className={`p-2 border ${
                          isPast ? 'bg-yellow-200' : 'cursor-pointer'
                        } ${selectedSlots.includes(slotKey) ? 'bg-blue-200' : ''}`}
                        onClick={() => !isPast && handleSlotClick(day, time)}
                      ></td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button
          onClick={saveAppointmentSlots}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Save Appointment Slots
        </button>
      </div>
      
      {/* Padding for app toolbar */}
      <div className="h-16"></div>
    </div>
  );
};

export default AppointmentOffline;
