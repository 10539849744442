import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../../atom/atoms";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { addSurveyQuestion } from "../../../store/pouch/survey-questions-pouch";
import { updateUserPhone } from "../../../store/pouch/user-pouch";

const CountryCodes = [{ code: "+60", flag: "🇲🇾", country: "Malaysia" }];

const QNum500 = () => {
  const [user, setUser] = useRecoilState(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const nicknameAnswer = useRecoilValue(questionAnswerState("100"));
  const [phoneAnswer, setPhoneAnswer] = useRecoilState(
    questionAnswerState("500"),
  );
  const [countryCodeAnswer, setCountryCodeAnswer] = useRecoilState(
    questionAnswerState("501"),
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    CountryCodes[0],
  );
  const [error, setError] = useState("");

  const nickname = nicknameAnswer?.surveyAnswerString || "";

  useEffect(() => {
    const savedPhoneNumber = phoneAnswer?.surveyAnswerString || "";
    const savedCountryCode = countryCodeAnswer?.surveyAnswerString || "";

    setPhoneNumber(savedPhoneNumber);
    const countryCodeObject = CountryCodes.find(
      (country) => country.code === savedCountryCode,
    );
    if (countryCodeObject) {
      setSelectedCountryCode(countryCodeObject);
    }
  }, [phoneAnswer, countryCodeAnswer]);

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.startsWith("06") || value.startsWith("60")) {
      value = value.slice(2);
    }
    if (value.startsWith("0") || value.startsWith("6")) {
      value = value.slice(1);
    }
    if (value.length <= 20) {
      setPhoneNumber(value);
      setError(""); // Clear error when user types
    }
  };

  const validatePhoneNumber = () => {
    if (phoneNumber.length < 9) {
      setError("Please provide a valid phone number (at least 9 digits)");
      return false;
    }
    setError("");
    return true;
  };

  const handlePhoneNumberKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSavePhoneNumber();
    }
  };

  const handleSavePhoneNumber = async () => {
    if (!validatePhoneNumber()) {
      return;
    }

    const phoneAnswerJson = {
      _id: `${user?.userEmail}-surveyQNum-500`,
      surveyQuestionNumber: "500",
      surveyAnswerString: phoneNumber,
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    const countryCodeAnswerJson = {
      _id: `${user?.userEmail}-surveyQNum-501`,
      surveyQuestionNumber: "501",
      surveyAnswerString: selectedCountryCode.code,
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    try {
      // Add survey questions
      await addSurveyQuestion(phoneAnswerJson);
      await addSurveyQuestion(countryCodeAnswerJson);

      // Update user phone and country code in PouchDB
      await updateUserPhone(user?.userEmail, phoneNumber, selectedCountryCode.code);

      setPhoneAnswer(phoneAnswerJson);
      setCountryCodeAnswer(countryCodeAnswerJson);

      setUser({
        ...user,
        userPhone: phoneNumber,
        userPhoneCountry: selectedCountryCode.code,
      });

      let userSurveyProgress = {
        currentQuestion: "600",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, userSurveyProgress });
      setCurrentQuestion("600");
    } catch (error) {
      console.error("Error saving survey questions or updating user phone:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    setCurrentQuestion("400");
  };

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {phoneNumber && (
            <span>
              {nickname}, can I ring you💬?
              <br />
              (for research only)
            </span>
          )}
          {!phoneNumber && (
            <Typewriter
              options={{
                strings: [
                  `${nickname}, can I ring you💬?<br/>(for research only)`,
                ],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <div className="flex flex-col items-center">
          <div className="flex items-center border rounded">
            <button type="button" className="flex items-center px-3">
              <span className="mr-2">{selectedCountryCode.flag}</span>
              <span>{selectedCountryCode.code}</span>
            </button>
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              onKeyDown={handlePhoneNumberKeyDown}
              placeholder="Your phone"
              className="p-2 rounded border-l"
              maxLength={20}
            />
          </div>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-3"
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSavePhoneNumber}
          className={`mt-2 px-4 py-2 bg-blue-500 text-white rounded ${
            phoneNumber === ""
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500"
          }`}
          disabled={phoneNumber === ""}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default QNum500;
