import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../atom/atoms";
import {
  lifestyleAtom,
  addLifestyleResponse,
} from "../../atom/lifestyle/lifestyle-atom";
import LifestyleQuestion from "./sub/Daily-02.1-LifestyleQuestion";
import { useNavigate } from "react-router-dom";
import { getUserObj } from "../../store/pouch/user-pouch";
import {
  getLocalDateOnly,
  getQuestionsArrayWithNickname,
} from "../../util/Util";
import { getLifestyleQuestionsByIds } from "../../store/pouch/lifestyle-pouch";

const DailyLifestyle = () => {
  const user = useRecoilValue(userState);
  const setLifestyleAtom = useSetRecoilState(lifestyleAtom);
  const [lifestyleResponse, setLifestyleResponse] = useState({});

  const nickname = user?.userNickName || "User";

  const [currentLifestyleQuestion, setCurrentLifestyleQuestion] = useState(1);

  const questions = useMemo(
    () => getQuestionsArrayWithNickname(nickname),
    [nickname],
  );

  const upsertLifestyleAnswer = (
    questionId,
    answer,
    options,
    setStateFunction,
  ) => {
    setStateFunction((prevState) => ({
      ...prevState,
      [questionId]: { answer, options },
      userEmail: user?.userEmail,
    }));
    if (currentLifestyleQuestion === questions.length) {
    } else {
      setCurrentLifestyleQuestion(currentLifestyleQuestion + 1);
    }
  };

  return (
    <>
      <LifestyleQuestion
        nickname={nickname}
        currentLifestyleQuestion={currentLifestyleQuestion}
        setCurrentLifestyleQuestion={setCurrentLifestyleQuestion}
        upsertLifestyleAnswer={upsertLifestyleAnswer}
        lifestyleResponse={lifestyleResponse}
        setLifestyleResponse={setLifestyleResponse}
        questions={questions}
      />

      <div className="fixed bottom-0 left-0 right-0 bg-white bg-opacity-90 shadow-md p-2 text-xs text-center text-gray-500">
        By participating this survey, you consent to provide data for fulfilment
        of MSc in Bioinformatics (Research) by Ong Lit Yit (student id 24110007)
        in Perdana Univerity, KL, Malaysia. Your data is protected by Malaysian
        Law and Personal Data Protection Act 2010 ("PDPA").
      </div>
    </>
  );
};

export default DailyLifestyle;
