import { atom, selector } from "recoil";
import { localStorageEffect } from "../../util/localStorageEffect";

export const calorieAtom = atom({
  key: "calorieAtom",
  default: {
    calories: [],
  },
  effects: [localStorageEffect("calorieAtom")],
});

export const calorieHistories = selector({
  key: "calorieHistoriesSelector",
  get: ({ get }) => get(calorieAtom).calories,
  set: ({ set }, newValue) =>
    set(calorieAtom, (prevState) => {
      const updatedHistory = [...prevState.calories];
      if (Array.isArray(newValue)) {
        newValue.forEach((newCalorie) => {
          const index = updatedHistory.findIndex(
            (calorie) => calorie.calorieId === newCalorie.calorieId,
          );
          if (index !== -1) {
            updatedHistory[index] = newCalorie;
          } else {
            updatedHistory.push(newCalorie);
          }
        });
      } else {
        const index = updatedHistory.findIndex(
          (calorie) => calorie.calorieId === newValue.calorieId,
        );
        if (index !== -1) {
          updatedHistory[index] = newValue;
        } else {
          updatedHistory.push(newValue);
        }
      }
      return {
        ...prevState,
        calories: updatedHistory,
      };
    }),
});
