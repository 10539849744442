import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  addHabitResponse,
  habitAtom,
  updateHabitAtomSelector,
} from "../../../atom/habit/habit-atom";
import { nanoid } from "nanoid";
import { addHabitQuestion } from "../../../store/pouch/habit-pouch";
import { userState } from "../../../atom/atoms";
import {
  convertYYYYMMDDToDateObj,
  convertYYYYMMDDToDateObjEOD,
  getEndOfDayISOStringZ,
  getLocalDateOnly,
  getStartOfDayISOStringZ,
} from "../../../util/Util";
import { ClaimCoins, MyCoinHistory } from "../../../util/CoinClaiming";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../../atom/coin/coin-atoms";
import {
  getCoinsByUserEmailAndDateTimeRange,
  getCoinsByUserEmailAndDateTimeString,
} from "../../../store/pouch/coin-pouch";
import { coinEarnedTodayState } from "../../../atom/coin/coin-today-atom";

const titlesAndQuestions = [
  {
    questionNum: "100",
    questionTitle: "Binge eating",
    questionText: "Did you binge eat today?",
  },
  {
    questionNum: "200",
    questionTitle: "Purging",
    questionText: "Did you have diarrhoea today?",
  },
  {
    questionNum: "300",
    questionTitle: ">2 hours of screen time",
    questionText: "Did you have more than 2 hours of screen time today?",
  },
  {
    questionNum: "400",
    questionTitle: "Felt guilt/shame about eating",
    questionText: "Did you felt guilt/shame about eating today?",
  },
  {
    questionNum: "500",
    questionTitle: "Consume drinks with Calories",
    questionText: "Did you have any drinks with Calories today?",
  },
  {
    questionNum: "600",
    questionTitle: "Consume fast food",
    questionText: "Did you have fast food today?",
  },
  {
    questionNum: "700",
    questionTitle: "Eat due to boredom/stress",
    questionText: "Did you eat due to boredom or stress today?",
  },
];

const DailyHabits = () => {
  const user = useRecoilValue(userState);
  const [habitState, setHabitState] = useRecoilState(habitAtom);
  const [selectedHabitAnswer, setSelectedHabitAnswer] = useState("no");
  const [clickedButton, setClickedButton] = useState(null);
  const navigate = useNavigate();
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const setHabitAtom = useSetRecoilState(updateHabitAtomSelector);
  const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);

  const updateHabitAtom = (userEmail, habitQuestionDate) => {
    setHabitAtom({
      userEmail,
      habitQuestionDate,
    });
  };

  const handleAnswer = (answer) => {
    const asyncWrapper = async () => {
      setSelectedHabitAnswer(answer);
      setClickedButton(answer);
      let todayDate = getLocalDateOnly();

      let answerJson = {
        _id: `${user?.userEmail}-habit-${todayDate}-${habitState?.currentHabitQuestionNumber}`,
        userEmail: user?.userEmail,
        habitQuestionDate: todayDate,
        habitQuestionDateTime: new Date().toISOString(),
        answer: answer,
      };

      await addHabitQuestion(answerJson);
      updateHabitAtom(user?.userEmail, todayDate);
      addHabitResponse(setHabitAtom, answerJson);
      if (
        habitState?.currentHabitQuestionNumber <
        titlesAndQuestions.length - 1
      ) {
        setHabitState((prevState) => ({
          ...prevState,
          currentHabitQuestionNumber: prevState.currentHabitQuestionNumber + 1,
        }));
      } else {
        setHabitState((prevState) => ({
          ...prevState,
          currentHabitQuestionNumber: 0,
        }));
        debugger;
        let toEarnArray = await ClaimCoins();
        let toEarn = toEarnArray?.reduce(
          (sum, transaction) => sum + transaction.coinsEarned,
          0,
        );

        // let todayCoinArray = await getCoinsByUserEmailAndDateTimeString(
        //   user?.userEmail,
        //   getStartOfDayISOStringZ(),
        //   getEndOfDayISOStringZ(),
        // );
        // let todayCoin = todayCoinArray?.reduce(
        //   (sum, transaction) => sum + transaction.coinsEarned,
        //   0,
        // );
        // setCoinEarnedToday(todayCoin);
        setCoinEarnedAmount(toEarn);
        if (toEarn) {
          setCoinEarnedAnimation(true);
          let coinsTodayArray = await MyCoinHistory(
            user?.userEmail,
            getStartOfDayISOStringZ(),
            getEndOfDayISOStringZ(),
          );

          let coinToday = coinsTodayArray?.reduce(
            (sum, transaction) => sum + transaction.coinsEarned,
            0,
          );
          setCoinEarnedToday(coinToday);
        }
        navigate("/fat-loss");
      }
    };
    asyncWrapper();
  };

  const handleBack = () => {
    if (habitState?.currentHabitQuestionNumber === 0) navigate("/daily");
    else
      setHabitState((prevState) => ({
        ...prevState,
        currentHabitQuestionNumber: prevState.currentHabitQuestionNumber - 1,
      }));
  };

  useEffect(() => {
    if (clickedButton) {
      const timer = setTimeout(() => {
        setClickedButton(null);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [clickedButton]);

  const currentQuestionData =
    titlesAndQuestions[habitState?.currentHabitQuestionNumber || 0];

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col items-center">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover mb-4"
        />
        <h2 className="text-center mb-2 text-xl font-bold text-gray-800">
          {currentQuestionData?.questionTitle}
        </h2>
        <div className="text-center mb-4 text-lg text-blue-600">
          <Typewriter
            options={{
              strings: [currentQuestionData?.questionText],
              autoStart: true,
              loop: false,
              cursor: "",
              delay: 50,
              deleteSpeed: Infinity,
            }}
          />
        </div>
        <div className="flex space-x-4 mb-4">
          <button
            type="button"
            onClick={() => handleAnswer("yes")}
            className={`px-4 py-2 rounded border-2 transition-colors duration-300 ${
              clickedButton === "yes"
                ? "border-blue-500 text-blue-500 bg-white"
                : "bg-blue-500 text-white"
            }`}
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faCheck} size="2x" />
              <span className="ml-2">Yes</span>
            </div>
          </button>
          <button
            type="button"
            onClick={() => handleAnswer("no")}
            className={`px-4 py-2 rounded border-2 transition-colors duration-300 ${
              clickedButton === "no"
                ? "border-blue-500 text-blue-500 bg-white"
                : "bg-blue-500 text-white"
            }`}
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faTimes} size="2x" />
              <span className="ml-2">No</span>
            </div>
          </button>
        </div>
        <button
          type="button"
          onClick={handleBack}
          className="px-4 py-2 rounded border-2 border-gray-300 text-gray-600 hover:bg-gray-100 transition-colors duration-300"
        >
          <div className="flex items-center">
            <FontAwesomeIcon icon={faArrowLeft} size="1x" />
            <span className="ml-2">Back</span>
          </div>
        </button>
      </div>
      <div className="bg-white p-4 mt-32">
        <p className="text-blue-500 text-center text-lg font-semibold">
          Question {(habitState?.currentHabitQuestionNumber || 0) + 1} of{" "}
          {titlesAndQuestions.length}
        </p>
      </div>
    </div>
  );
};

export default DailyHabits;
