import React from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../atom/atoms';

const LevelProgress = ({ currentLevel, nextLevel, task, progress, total }) => {

  const user = useRecoilValue(userState);
  const percentage = (progress / total) * 100;

  return (<>
    <div className="bg-white rounded-lg shadow-md p-4 m-4">
      <h3 className="text-lg font-semibold mb-2">
        Level 1 to Level {user?.userLevel}
      </h3>
      
      <div className="flex items-center mb-2">
        <div className="w-4 h-4 rounded-full bg-blue-500 mr-2"></div>
        <p className="text-sm">
          you did it at DATE
        </p>
      </div>
      
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div 
          className="bg-green-500 h-2.5 rounded-full" 
          style={{ width: `${100}%` }}
        ></div>
      </div>
    </div>
    <div className="bg-white rounded-lg shadow-md p-4 m-4">
      <h3 className="text-lg font-semibold mb-2">
        In order to reach Level {nextLevel}
      </h3>
      
      <div className="flex items-center mb-2">
        <div className="w-4 h-4 rounded-full bg-blue-500 mr-2"></div>
        <p className="text-sm">
          {task} ({progress}/{total})
        </p>
      </div>
      
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div 
          className="bg-green-500 h-2.5 rounded-full" 
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  </>
  );
};

export default LevelProgress;
