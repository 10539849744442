import { atom, selector } from "recoil";
import { localStorageEffect } from "../../util/localStorageEffect";

export const chatAtom = atom({
  key: "chatAtom",
  default: {
    activeTopicId: null,
    chats: [],
  },
  effects: [localStorageEffect("chatAtom")],
});

export const activeTopicIdState = selector({
  key: "activeTopicIdState",
  get: ({ get }) => get(chatAtom).activeTopicId,
  set: ({ set }, newValue) =>
    set(chatAtom, (prevState) => ({
      ...prevState,
      activeTopicId: newValue,
    })),
});

export const updateChatAtom = selector({
  key: "updateChatAtom",
  get: ({ get }) => get(chatAtom),
  set: ({ set }, newValue) => {
    set(chatAtom, (prevState) => ({
      ...prevState,
      ...newValue,
    }));
  },
});

export const chatHistories = selector({
  key: "chatHistoriesSelector",
  get: ({ get }) => get(chatAtom).chats,
  set: ({ set }, newValue) =>
    set(chatAtom, (prevState) => {
      const updatedHistory = [...prevState.chats];
      if (Array.isArray(newValue)) {
        newValue.forEach((newCalorie) => {
          const index = updatedHistory.findIndex(
            (chat) => chat.chatId === newCalorie.chatId,
          );
          if (index !== -1) {
            updatedHistory[index] = newCalorie;
          } else {
            updatedHistory.push(newCalorie);
          }
        });
      } else {
        const index = updatedHistory.findIndex(
          (chat) => chat.chatId === newValue.chatId,
        );
        if (index !== -1) {
          updatedHistory[index] = newValue;
        } else {
          updatedHistory.push(newValue);
        }
      }
      return {
        ...prevState,
        chats: updatedHistory,
      };
    }),
});
