import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVenus, faMars } from "@fortawesome/free-solid-svg-icons";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { userState } from "../../../atom/atoms";
import { updateUserGender } from "../../../store/pouch/user-pouch";
import { addSurveyQuestion } from "../../../store/pouch/survey-questions-pouch";

const QNum200 = () => {
  const user = useRecoilValue(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const nicknameAnswer = useRecoilValue(questionAnswerState("100"));
  const [genderAnswer, setGenderAnswer] = useRecoilState(
    questionAnswerState("200"),
  );

  const nickname = nicknameAnswer?.surveyAnswerString || "";
  const selectedGender = genderAnswer?.surveyAnswerString || "";

  const handleGenderSelect = (gender) => {
    const handleGenderSelectAsync = async (gender) => {
      let answerJson = {
        _id: `${user?.userEmail}-surveyQNum-200`,
        surveyQuestionNumber: "200",
        surveyAnswerString: gender,
        surveyAnswerList: [],
        userEmail: user?.userEmail,
      };
      setGenderAnswer(answerJson);

      try {
        await addSurveyQuestion(answerJson);
        await updateUserGender(user?.userEmail, gender);
        
        let userSurveyProgress = {
          currentQuestion: "300",
          isComplete: false,
          completionDateTime: null,
          earnedCoinAmount: 0,
          earnedCoinDatetime: null,
        };

        await addSurvey({ _id: user?.userEmail, userSurveyProgress });
        setCurrentQuestion("300");
      } catch (error) {
        console.error("Error saving survey question:", error);
        // Handle error (e.g., show error message to user)
      }
    };

    handleGenderSelectAsync(gender);
  };

  const handleBack = () => {
    setCurrentQuestion("100");
  };
  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {selectedGender && <span>Your gender, {nickname}</span>}
          {!selectedGender && (
            <Typewriter
              options={{
                strings: [`Your gender, ${nickname}?`],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={() => handleGenderSelect("female")}
          className={`px-5 py-2 rounded mr-4 border-2 ${
            selectedGender === "female"
              ? "border-pink-500 text-pink-500 bg-white"
              : "bg-pink-500 text-white"
          }`}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faVenus} size="2x" />
            <span>&nbsp;Female</span>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleGenderSelect("male")}
          className={`px-4 py-2 rounded border-2 ${
            selectedGender === "male"
              ? "border-blue-500 text-blue-500 bg-white"
              : "bg-blue-500 text-white"
          }`}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faMars} size="2x" />
            <span>&nbsp;&nbsp;Male&nbsp;&nbsp;</span>
          </div>
        </button>
      </div>
      <div className="flex justify-center mt-2 ml-1">
        <button
          type="button"
          onClick={handleBack}
          className={`mt-2 px-4 py-2 bg-gray-500 text-white rounded ${
            selectedGender ? "mr-5" : ""
          }`}
        >
          Back
        </button>
        {selectedGender && (
          <button
            type="button"
            onClick={() => setCurrentQuestion("300")}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Next
          </button>
        )}
      </div>
    </>
  );
};

export default QNum200;
