import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../../atom/atoms";
import { getUserObj } from "../../../store/pouch/user-pouch";
import {
  surveyDataState,
  questionAnswerState,
} from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { addSurveyQuestion } from "../../../store/pouch/survey-questions-pouch";
import { updateUserNickname } from "../../../store/pouch/user-pouch";

const QNum100 = () => {
  const [user, setUser] = useRecoilState(userState);
  const [questionAnswer, setQuestionAnswer] = useRecoilState(
    questionAnswerState("100"),
  );
  const surveyData = useRecoilValue(surveyDataState);
  const setCurrentQuestion = useSetRecoilState(currentQuestionState);

  const [isTouched, setIsTouched] = useState(false);
  const [localNickname, setLocalNickname] = useState("");

  useEffect(() => {
    if (user?.userNickName) setLocalNickname(user?.userNickName);
  }, [user?.userNickName]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      setLocalNickname(value);
    }
  };

  const handleNickNameKeyDown = (event) => {
    if (event.key === "Enter" && localNickname !== "") {
      saveUpsert();
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
  };

  const saveUpsert = async () => {
    try {
      // Update user nickname in the database
      await updateUserNickname(user.userEmail, localNickname);

      // Update local user state
      setUser({ ...user, userNickName: localNickname });
      
      const surveyQuestion = {
        _id: `${user.userEmail}-surveyQNum-100`,
        surveyQuestionNumber: "100",
        surveyAnswerString: localNickname,
        surveyAnswerList: [],
        userEmail: user.userEmail,
      };

      await addSurveyQuestion(surveyQuestion);
      
      setQuestionAnswer(surveyQuestion);

      let userSurveyProgress = {
        currentQuestion: "200",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user.userEmail, userSurveyProgress });

      setCurrentQuestion("200");
    } catch (error) {
      console.error("Error saving survey question:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <>
      <div className="flex justify-center mt-4">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
        <div className="text-center text-xl text-blue-600 ml-2">
          <span>Let's setup Profile, </span>
          <br />
          <span>{user?.userName || "Guest"}</span>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {localNickname && <span>What's your nickname?</span>}
          {!localNickname && (
            <Typewriter
              options={{
                strings: [`What's your nickname?`],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="flex flex-col items-center mt-2">
          <input
            type="text"
            value={localNickname}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleNickNameKeyDown}
            placeholder="Enter your nickname"
            className="border p-2 rounded"
            maxLength={15}
          />
          {isTouched && localNickname === "" && (
            <span className="text-red-500 mt-2">
              Nickname must not be empty
            </span>
          )}
          <button
            type="button"
            onClick={saveUpsert}
            className={`mt-2 px-4 py-2 text-white rounded ${
              localNickname === ""
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500"
            }`}
            disabled={localNickname === ""}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default QNum100;
