import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
import { getLocalDateOnly } from "../../util/Util"; // Assuming you have this utility function

PouchDB.plugin(PouchDBFind);

const levelUpDB = new PouchDB("levelUpDB");

// Create an index on userEmail and levelUpDateTime
levelUpDB.createIndex({
  index: { fields: ["userEmail", "levelUpDateTime"] }
}).then(() => {
  console.log("Index created on userEmail and levelUpDateTime");
}).catch((err) => {
  console.error("Error creating index:", err);
});

export const addLevelUp = async (userEmail, level) => {
  try {
    const levelUp = {
      _id: `${userEmail}-Level-${level}`,
      userEmail,
      levelUpDate: getLocalDateOnly(),
      levelUpDateTime: new Date().toISOString()
    };
    await levelUpDB.put(levelUp);
    console.log("Level up added successfully");
    return levelUp;
  } catch (error) {
    console.error("Error adding level up:", error);
    throw error;
  }
};

export const getLastLevelUp = async (userEmail) => {
  try {
    const result = await levelUpDB.find({
      selector: {
        userEmail: userEmail
      },
      sort: [{ levelUpDateTime: 'desc' }],
      limit: 1
    });
    return result.docs[0] || null;
  } catch (error) {
    console.error("Error getting last level up:", error);
    throw error;
  }
};

export const getLevelUpByLevel = async (userEmail, level) => {
  try {
    const result = await levelUpDB.get(`${userEmail}-Level-${level}`);
    return result;
  } catch (error) {
    if (error.name === 'not_found') {
      return null;
    }
    console.error("Error getting level up by level:", error);
    throw error;
  }
};

export default levelUpDB;
