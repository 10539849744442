import React, { useEffect, useRef } from "react";
import MealItem from "./MealItem";
import { useNavigate } from "react-router-dom";
import { userState } from "../../atom/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { getMealsByUserEmailAndEpoch } from "../../store/pouch/meal-pouch";
import { mealAtom } from "../../atom/meal/meal-atom";
import { format, isToday, isYesterday, isThisWeek, isThisYear } from "date-fns";

const MealGallery = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const [mealsState, setMealsState] = useRecoilState(mealAtom);

  const handleBack = () => {
    navigate("/mealsnap");
  };

  useEffect(() => {
    if (effectRan.current === false) {
      const asyncWrapper = async () => {
        let mealRecords = await getMealsByUserEmailAndEpoch(
          user?.userEmail,
          "2014-01-01T00:00:00.000Z",
          "2034-01-01T00:00:00.000Z",
        );

        setMealsState((prevState) => ({
          ...prevState,
          mealGallery: mealRecords,
        }));

        effectRan.current = true;
      };
      asyncWrapper();
    }
  }, [setMealsState, user?.userEmail]);

  const getTimeCluster = (date) => {
    if (isToday(date)) {
      return "Today";
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else if (isThisWeek(date)) {
      return "This Week";
    } else if (isThisYear(date)) {
      return format(date, "MMMM");
    } else {
      return format(date, "yyyy");
    }
  };

  const groupMealsByTimeCluster = () => {
    const grouped = {};
    mealsState?.mealGallery?.forEach((meal) => {
      const date = new Date(meal.mealDateTime);
      const cluster = getTimeCluster(date);
      if (!grouped[cluster]) {
        grouped[cluster] = [];
      }
      grouped[cluster].push(meal);
    });
    return grouped;
  };

  const groupedMeals = groupMealsByTimeCluster();

  return (
    <>
      <button onClick={handleBack} className="backButton"></button>
    {groupedMeals.length && <>
      <div className="flex flex-col p-4">
        {Object.entries(groupedMeals).map(([cluster, meals]) => (
          <div key={cluster} className="mb-6">
            <h2 className="text-xl font-bold mt-5 mb-2">{cluster}</h2>
            <div className="flex flex-wrap justify-start gap-4">
              {meals.map((meal, index) => (
                <MealItem key={index} meal={meal} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>}
    {!groupedMeals.length && <>
    <div className="mt-16">
      
    You do not have any meal history.
    </div>
    </>}
    </>
  );
};

export default MealGallery;
