import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

export const mealDB = new PouchDB("mealDB");

mealDB
  .createIndex({
    index: {
      fields: [
        "userEmail",
        "mealId",
        "mealDate",
        "mealDateTime",
        "mealEpoch",
        "mealType",
        "mealRemarks",
        "mealCalorieRef",
        "mealCalorieProportion",
        "mealCalorieFull",
        "mealAttachmentURLArray",
        "mealAttachmentB64Array",
        "mealAttachmentMimeTypeArray",
        "mealIsDeleted",
      ],
    },
  })
  .then()
  .catch((err) => {
    console.error("Error creating index:", err);
  });

mealDB
  .createIndex({
    index: {
      fields: ["userEmail", "mealId"],
    },
  })
  .then()
  .catch((err) => {
    console.error("Error creating composite index:", err);
  });

mealDB
  .createIndex({
    index: {
      fields: ["userEmail", "mealDateTime", "mealEpoch"],
    },
  })
  .then()
  .catch((err) => {
    console.error("Error creating composite index:", err);
  });

await mealDB.createIndex({
  index: {
    fields: ["userEmail", "mealEpoch"],
  },
});

mealDB.viewCleanup();

export const findMealByUserAndId = async (userEmail, mealId) => {
  try {
    const result = await mealDB.find({
      selector: {
        userEmail: userEmail,
        mealId: mealId,
      },
    });

    if (result.docs.length > 0) {
      console.log("Meal record found:", result.docs[0]);
      return result.docs[0];
    } else {
      console.log("No meal record found for the given criteria.");
      return null;
    }
  } catch (error) {
    console.error("Error finding meal record:", error);
    return null;
  }
};

export const getMealsByUserEmailAndEpoch = async (
  userEmail,
  startDateTime,
  endDateTime,
) => {
  try {
    let selector = {
      userEmail: userEmail,
      mealEpoch: {
        $gte: new Date(startDateTime).getTime(),
        $lte: new Date(endDateTime).getTime(),
      },
    };

    const result = await mealDB.find({
      selector: selector,
      sort: [{ userEmail: "asc", mealEpoch: "desc" }],
    });
    return result.docs.reverse();
  } catch (err) {
    debugger;
    return [];
  }
};

export const addMealItem = async (item) => {
  try {
    const existingItem = await mealDB.get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await mealDB.put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await mealDB.get(item._id);
        item._rev = resolvedItem._rev;
        await mealDB.put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};
