import { atom, selector } from "recoil";
import { localStorageEffect } from "../util/localStorageEffect";

export const persistentAtom = atom({
  key: "persistentAtom",
  default: {
    completedStartScreen: false,
    stateOfIntroduction: "0",
    user: null,
  },
  effects: [localStorageEffect("persistentAtom")],
});

// Selector for completedStartScreen
export const completedStartScreenState = selector({
  key: "completedStartScreenState",
  get: ({ get }) => get(persistentAtom).completedStartScreen,
  set: ({ set }, newValue) => {
    return set(persistentAtom, (prevState) => ({
      ...prevState,
      completedStartScreen: newValue,
    }));
  },
});

// Selector for stateOfIntroduction
export const stateOfIntroductionState = selector({
  key: "stateOfIntroductionState",
  get: ({ get }) => get(persistentAtom).stateOfIntroduction,
  set: ({ set }, newValue) =>
    set(persistentAtom, (prevState) => ({
      ...prevState,
      stateOfIntroduction: newValue,
    })),
});

// Selector for userState
export const userState = selector({
  key: "userState",
  get: ({ get }) => get(persistentAtom).user,
  set: ({ set }, newValue) =>
    set(persistentAtom, (prevState) => ({
      ...prevState,
      user: newValue,
    })),
});
