import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const CalorieHistory = () => {
  const navigate = useNavigate();

  // Hardcoded values
  const caloriesConsumed = 500;
  const calorieGoal = 2000;
  const caloriesBurned = 300; // Example value

  // Calculate percentages
  const consumedPercentage = (caloriesConsumed / calorieGoal) * 100;

  // SVG parameters
  const centerX = 100;
  const centerY = 100;
  const radius = 80;

  // Calculate end angle for consumed calories arc (starting from 12 o'clock position)
  const endAngle = (consumedPercentage / 100) * 360;
  const endX = centerX + radius * Math.sin((endAngle * Math.PI) / 180);
  const endY = centerY - radius * Math.cos((endAngle * Math.PI) / 180);

  // Determine if the arc should take the long path (large-arc-flag)
  const largeArcFlag = endAngle > 180 ? 1 : 0;

  return (
    <div className="p-4">
      <button onClick={() => navigate(-1)} className="mb-4">
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </button>

      <div className="flex justify-center">
        <svg width="200" height="200" viewBox="0 0 200 200">
          {/* Background circle */}
          <circle cx={centerX} cy={centerY} r={radius} fill="#f0f0f0" />

          {/* Consumed calories arc */}
          <path
            d={`M ${centerX} ${centerY - radius} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`}
            fill="none"
            stroke="#4CAF50"
            strokeWidth="20"
          />

          {/* Inner circle for calories burned */}
          <circle cx={centerX} cy={centerY} r="60" fill="#FFA500" />

          {/* Text for calories burned */}
          <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            fill="white"
            fontSize="20"
            dy=".3em"
          >
            {caloriesBurned}
          </text>

          {/* Calorie goal text */}
          <text
            x={centerX}
            y={centerY + 95}
            textAnchor="middle"
            fill="#333"
            fontSize="14"
          >
            Goal: {calorieGoal} cal
          </text>
        </svg>
      </div>

      <div className="mt-4 text-center">
        <p>Calories Consumed: {caloriesConsumed}</p>
        <p>Calories Burned: {caloriesBurned}</p>
      </div>
    </div>
  );
};

export default CalorieHistory;
