import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faTimes } from "@fortawesome/free-solid-svg-icons";
// import { UseApi } from "../../../api/UseApi";

const accordionItems = [
  "Admin Summary",
  "Charts",
  "Config",
  "Checkpoints",
  "Data",
  "Leaderboard",
  "Progress",
  "Reports",
  "Updates",
];

const ResearchAccordion = ({ setPlayEarnCoin, setCoinsEarned }) => {
  const [expandedItems, setExpandedItems] = useState([]);
  const [metricsData, setMetricsData] = useState(null);
  const [emails, setEmails] = useState([]);
  const [inputEmail, setInputEmail] = useState("");
  // const { getWithAuth, postWithAuth } = UseApi();

  const toggleAccordion = (item) => {
    setExpandedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item],
    );
  };

  const collapseAll = () => {
    setExpandedItems([]);
  };

  // const refreshMetrics = async () => {
  //   try {
  //     const response = await getWithAuth("/api/research/refresh", "internal");
  //     if (response) {
  //       setMetricsData(response.data);
  //     }
  //   } catch (error) {
  //     console.error("Failed to refresh metrics:", error);
  //   }
  // };

  const handleTestPlayEarnedCoin = () => {
    setCoinsEarned(8);
    setPlayEarnCoin(true);
  };

  const handleInputChange = (e) => {
    setInputEmail(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && inputEmail.trim()) {
      setEmails([...emails, inputEmail.trim()]);
      setInputEmail("");
    }
  };

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  // const handleBulkSoftDelete = async () => {
  //   if (inputEmail) {
  //     setEmails([...emails, inputEmail.trim()]);
  //     setInputEmail("");
  //   }
  //   debugger;
  //   if (emails.length === 0) {
  //     alert("Please enter at least one email address.");
  //     return;
  //   }

  //   try {
  //     const response = await postWithAuth(
  //       "/api/research/bulkSoftDeleteUserData",
  //       "internal",
  //       { user_emails: emails },
  //     );
  //     if (response) {
  //       alert(
  //         `Bulk soft delete completed. ${response.data.details.modified_count} profiles updated.`,
  //       );
  //       setEmails([]);
  //     }
  //   } catch (error) {
  //     console.error("Failed to perform bulk soft delete:", error);
  //     alert("Failed to perform bulk soft delete. Please try again.");
  //   }
  // };

  return (
    <div>
      {accordionItems.map((item) => (
        <div
          key={item}
          style={{
            backgroundColor: "#3b82f6",
            color: "white",
            margin: "5px 0",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <div
            onClick={() => toggleAccordion(item)}
            style={{ cursor: "pointer" }}
          >
            {item}
          </div>
          {expandedItems.includes(item) && (
            <div
              style={{
                padding: "10px",
                backgroundColor: "#1e3a8a",
                borderRadius: "5px",
              }}
            >
              {true && (
                <button
                  onClick={handleTestPlayEarnedCoin}
                  style={{
                    padding: "10px 15px",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                >
                  Test Play Earned Coin
                </button>
              )}
              {item === "Admin Summary" && (
                <div>
                  {/* onClick={refreshMetrics} */}
                  <button
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    Refresh Metrics
                  </button>
                  {metricsData && (
                    <pre
                      style={{
                        backgroundColor: "#f3f4f6",
                        padding: "10px",
                        borderRadius: "5px",
                        maxHeight: "400px",
                        overflowY: "auto",
                        color: "#333",
                      }}
                    >
                      {JSON.stringify(metricsData, null, 2)}
                    </pre>
                  )}
                </div>
              )}
              {item === "Updates" && (
                <div>
                  <h3>Bulk Soft Delete User Profiles</h3>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom: "10px",
                    }}
                  >
                    {emails.map((email, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "#4b5563",
                          color: "white",
                          padding: "5px 10px",
                          margin: "2px",
                          borderRadius: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {email}
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => removeEmail(email)}
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                        />
                      </div>
                    ))}
                  </div>
                  <input
                    type="email"
                    value={inputEmail}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    placeholder="Enter email addresses"
                    style={{
                      width: "100%",
                      padding: "8px",
                      marginBottom: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      color: "blue",
                    }}
                  />
                  {/* onClick={handleBulkSoftDelete} */}
                  <button
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#dc2626",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Bulk Soft Delete
                  </button>
                </div>
              )}
              Content for {item}
            </div>
          )}
        </div>
      ))}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          cursor: "pointer",
          backgroundColor: "#93c5fd",
          borderRadius: "50%",
          padding: "10px",
        }}
        onClick={collapseAll}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </div>
    </div>
  );
};

export default ResearchAccordion;
