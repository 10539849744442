import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import WeightPicker from "./WeightPicker";
import { userState } from "../../../atom/atoms";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { addSurveyQuestion } from "../../../store/pouch/survey-questions-pouch";
import { updateUserInitialWeight } from "../../../store/pouch/user-pouch";

const QNum600 = () => {
  const [user, setUser] = useRecoilState(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const nicknameAnswer = useRecoilValue(questionAnswerState("100"));
  const [weightAnswer, setWeightAnswer] = useRecoilState(
    questionAnswerState("600"),
  );
  const [weight, setWeight] = useState("");
  const [error, setError] = useState("");

  const nickname = nicknameAnswer?.surveyAnswerString || "";

  useEffect(() => {
    setWeight(weightAnswer?.surveyAnswerString || "");
  }, [weightAnswer]);

  const handleSaveWeight = async () => {
    if (parseFloat(weight) < 30) {
      setError("Please provide a valid weight.");
      return;
    }

    setError("");

    const answerJson = {
      _id: `${user?.userEmail}-surveyQNum-600`,
      surveyQuestionNumber: "600",
      surveyAnswerString: weight,
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    try {
      // Add survey question
      await addSurveyQuestion(answerJson);

      // Update user's initial weight in PouchDB
      await updateUserInitialWeight(user?.userEmail, parseFloat(weight));

      setWeightAnswer(answerJson);

      let userSurveyProgress = {
        currentQuestion: "700",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, userSurveyProgress });
      
      // Update local user state
      setUser(prevUser => ({
        ...prevUser,
        userInitialWeightKg: parseFloat(weight)
      }));

      setCurrentQuestion("700");
    } catch (error) {
      console.error("Error saving survey question or updating user weight:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    setCurrentQuestion("500");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSaveWeight();
    }
  };

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {weight && (
            <>
              {nickname}, to understand
              <br /> your overall health,
              <br />
              mind sharing your weight🏋️‍♀️?
            </>
          )}
          {!weight && (
            <Typewriter
              options={{
                strings: [
                  `${nickname}, to understand<br/> your overall health,<br/>mind sharing your weight🏋️‍♀️?`,
                ],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <WeightPicker
          weight={weight}
          setWeight={setWeight}
          handleKeyPress={handleKeyPress}
        />
      </div>
      {error && (
        <div className="flex justify-center mt-2">
          <p className="text-red-500">{error}</p>
        </div>
      )}
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSaveWeight}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
        >
          Next
        </button>
      </div>
    </>
  );
};

export default QNum600;
