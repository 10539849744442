import {
  faPersonRunning,
  faPersonSwimming,
  faFire,
  faBicycle,
  faMusic,
  faOm,
  faDumbbell,
  faArrowUpFromGroundWater,
  faChevronDown,
  faForwardStep,
  faRulerHorizontal,
} from "@fortawesome/free-solid-svg-icons";
export const ExerciseCalories = {
  workoutTypes: [
    {
      name: "Running",
      faIcon: faPersonRunning,
      caloriesPerMinute: -8,
      reps: null,
    },
    {
      name: "Swimming",
      faIcon: faPersonSwimming,
      caloriesPerMinute: -6,
      reps: null,
    },
    {
      name: "HIIT",
      faIcon: faFire,
      caloriesPerMinute: -10,
      reps: null,
    },
    {
      name: "Cycling",
      faIcon: faBicycle,
      caloriesPerMinute: -5,
      reps: null,
    },
    {
      name: "Zumba",
      faIcon: faMusic,
      caloriesPerMinute: -4,
      reps: null,
    },
    {
      name: "Yoga",
      faIcon: faOm,
      caloriesPerMinute: -2,
      reps: null,
    },
    {
      name: "Push-ups",
      faIcon: faDumbbell,
      caloriesPerMinute: -3,
      reps: null,
    },
    {
      name: "Sit-ups",
      faIcon: faArrowUpFromGroundWater,
      caloriesPerMinute: -2.5,
      reps: null,
    },
    {
      name: "Squats",
      faIcon: faChevronDown,
      caloriesPerMinute: -3,
      reps: null,
    },
    {
      name: "Lunges",
      faIcon: faForwardStep,
      caloriesPerMinute: -3,
      reps: null,
    },
    {
      name: "Plank",
      faIcon: faRulerHorizontal,
      caloriesPerMinute: -2,
      reps: null,
    },
  ],
};
