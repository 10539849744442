import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import { userState } from "../../../atom/atoms";
import CurrencyInput from "react-currency-input-field";
import "./WeightPicker.css";
import { updateUserInitialWeight } from "../../../store/pouch/user-pouch";

const WeightPicker = ({ weight, setWeight, handleKeyPress }) => {
  const [weightAnswer, setWeightAnswer] = useRecoilState(
    questionAnswerState("600"),
  );
  const user = useRecoilValue(userState);
  const [inputClass, setInputClass] = useState("input-unfocused");

  const handleWeightChange = (value) => {
    if (value === null || value === "") {
      setWeight("");
      updateSurveyAnswer("", []);
      return;
    }

    if (value === ".") {
      setWeight("0.");
      updateSurveyAnswer("0.", []);
      return;
    }

    if (value && (value.match(/\./g) || []).length > 1) {
      return;
    }

    if (value && value.endsWith(".")) {
      setWeight(value);
      updateSurveyAnswer(value, []);
      return;
    }

    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && parsedValue <= 199.9) {
      setWeight(parsedValue.toString());
      updateSurveyAnswer(parsedValue.toString(), []);
      
      // Update user's initial weight
      updateUserInitialWeight(user.userEmail, parsedValue)
        .then(() => console.log("User initial weight updated"))
        .catch(error => console.error("Error updating user initial weight:", error));
    } else {
      setWeight("");
      updateSurveyAnswer("", []);
    }
  };

  const updateSurveyAnswer = (answerString, answerList) => {
    setWeightAnswer({
      surveyQuestionNumber: "600",
      surveyAnswerString: answerString,
      surveyAnswerList: answerList,
    });
  };

  useEffect(() => {
    setWeight(weightAnswer?.surveyAnswerString || "");
  }, [weightAnswer, setWeight]);

  return (
    <div className="flex justify-center items-center">
      <CurrencyInput
        className={`text-center ${inputClass}`}
        style={{
          fontSize: "3rem",
          width: "55vw",
          height: "4rem",
        }}
        value={weight}
        decimalsLimit={1}
        onValueChange={handleWeightChange}
        allowNegativeValue={false}
        disableGroupSeparators={true}
        placeholder="0.0"
        onFocus={() => setInputClass("input-focused")}
        onBlur={() => {
          setInputClass("input-unfocused");
          if (weight && weight.endsWith(".")) {
            const updatedWeight = weight + "0";
            setWeight(updatedWeight);
            updateSurveyAnswer(updatedWeight, []);
          }
        }}
        onKeyPress={handleKeyPress}
      />
      <span className="ml-2" style={{ fontSize: "3rem" }}>
        kg
      </span>
    </div>
  );
};

export default WeightPicker;
