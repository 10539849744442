import React, { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { userState } from "../../../atom/atoms";
import { addSurveyQuestion } from "../../../store/pouch/survey-questions-pouch";
import { updateUserAgeGroup } from "../../../store/pouch/user-pouch";

const QNum300 = () => {
  const [user, setUser] = useRecoilState(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const [ageGroupAnswer, setAgeGroupAnswer] = useRecoilState(
    questionAnswerState("300"),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedAgeGroup = ageGroupAnswer?.surveyAnswerString || "";

  const handleAgeGroupSelect = async (ageGroup) => {
    const answerJson = {
      _id: `${user?.userEmail}-surveyQNum-300`,
      surveyQuestionNumber: "300",
      surveyAnswerString: ageGroup,
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    try {
      await addSurveyQuestion(answerJson);
      await updateUserAgeGroup(user?.userEmail, ageGroup);
      
      setAgeGroupAnswer(answerJson);
      setIsModalOpen(false);

      let userSurveyProgress = {
        currentQuestion: "400",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, userSurveyProgress });
      setCurrentQuestion("400");

      // Update local user state
      setUser(prevUser => ({
        ...prevUser,
        userAgeGroup: ageGroup
      }));
    } catch (error) {
      console.error("Error saving survey question or updating user age group:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    setCurrentQuestion("200");
  };

  const handleNext = () => {
    setCurrentQuestion("400");
  };

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {selectedAgeGroup && <span>How many 🎂birthdays you had?</span>}
          {!selectedAgeGroup && (
            <Typewriter
              options={{
                strings: [`How many 🎂birthdays you had?`],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className={`px-4 py-2 rounded ${
            selectedAgeGroup
              ? "border border-blue-500 bg-white text-blue-500"
              : "bg-blue-500 text-white"
          }`}
        >
          {selectedAgeGroup || "Select Age Group"}
        </button>
      </div>
      {selectedAgeGroup && (
        <div className="flex justify-center mt-2">
          <button
            type="button"
            onClick={handleBack}
            className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-3"
          >
            Back
          </button>
          <button
            type="button"
            onClick={handleNext}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Next
          </button>
        </div>
      )}
      {!selectedAgeGroup && (
        <div className="flex justify-center mt-2">
          <button
            type="button"
            onClick={handleBack}
            className="mt-2 px-4 py-2 bg-gray-500 text-white rounded"
          >
            Back
          </button>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Select Your Age Group</h2>
            <div className="flex flex-col space-y-2">
              {["≤20", "21-30", "31-40", "41-50", "51-60", "≥61"].map(
                (ageGroup) => (
                  <button
                    key={ageGroup}
                    onClick={() => handleAgeGroupSelect(ageGroup)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    {ageGroup}
                  </button>
                ),
              )}
            </div>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default QNum300;
