import React, { useState, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { userState } from "../../../atom/atoms";
import { countProgramDay } from "../../../util/UserProgram";
import { getTotalCoinsEarned, getTotalCoinsSpent } from "../../../store/pouch/coin-pouch";
import { getWeightByUserEmailAndDateTimeString } from "../../../store/pouch/weight-pouch";
import { getUserObj } from "../../../store/pouch/user-pouch";
import "./NextLevelHorizontalViewer.css";

const NextLevelHorizontalViewer = ({ onItemClick }) => {
  const user = useRecoilValue(userState);
  const [programDay, setProgramDay] = useState(0);
  const [totalCoinsEarned, setTotalCoinsEarned] = useState(0);
  const [totalCoinsSpent, setTotalCoinsSpent] = useState(0);
  const [weightChangeStatus, setWeightChangeStatus] = useState("Weight change (N/A)");
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const fetchData = async () => {
        if (user?.userEmail) {
          
          if (user?.userProgramStartDateTime) {
            const calculatedProgramDay = countProgramDay(user.userProgramStartDateTime);
            setProgramDay(calculatedProgramDay);

            // Fetch user object to get initial weight
            const userObj = await getUserObj(user.userEmail);
            const initialWeight = userObj?.userInitialWeightKg;

            // Fetch weight data
            const weights = await getWeightByUserEmailAndDateTimeString(
              user.userEmail,
              user.userProgramStartDateTime,
              new Date().toISOString()
            );
            if (weights.length > 0 && initialWeight) {
              const latestWeight = weights[0].weightKg;
              const weightChange = (latestWeight - initialWeight).toFixed(2);
              
              if (weightChange > 0) {
                setWeightChangeStatus(`Weight change +${weightChange}kg`);
              } else if (weightChange < 0) {
                setWeightChangeStatus(`Weight change ${weightChange}kg`);
              } else {
                setWeightChangeStatus("Weight unchanged");
              }
            } else {
              setWeightChangeStatus("Weight change (N/A)");
            }
          } else {
            console.warn("userProgramStartDateTime is not set");
            setProgramDay(0);
          }

          const coinsEarned = await getTotalCoinsEarned(user.userEmail);
          setTotalCoinsEarned(coinsEarned);

          const coinsSpent = await getTotalCoinsSpent(user.userEmail);
          setTotalCoinsSpent(coinsSpent);
        }
      };

      fetchData();

      return () => {
        effectRan.current = true;
      };
    }
  }, [user?.userEmail, user?.userProgramStartDateTime]);

  const handleItemClick = (item) => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  return (
    <div className="ml-3 pl-2 horizontal-scroll-container shadow-md shadow-blue-800/35 rounded-r-none rounded-l-lg">
      <div className="horizontal-scroll-content">
        <div className="scroll-panel" onClick={() => handleItemClick("Program Day")}>
          <p className="text-lg">Day {programDay} of Program</p>
        </div>
        <div className="scroll-panel" onClick={() => handleItemClick("Coins Earned")}>
          <p className="text-lg">Earned {totalCoinsEarned} coins</p>
        </div>
        <div className="scroll-panel" onClick={() => handleItemClick("Coins Spent")}>
          <p className="text-lg">Spend {totalCoinsSpent} coins</p>
        </div>
        <div className="scroll-panel" onClick={() => handleItemClick("Coin Balance")}>
          <p className="text-lg">Coin balance {totalCoinsEarned - totalCoinsSpent}</p>
        </div>
        <div className="scroll-panel" onClick={() => handleItemClick("Weight Change")}>
          <p className="text-lg">{weightChangeStatus}</p>
        </div>
        <div className="scroll-panel" onClick={() => handleItemClick("Level Progress")}>
          <p className="text-lg">Level Progress</p>
        </div>
        <div className="scroll-panel-spacer"></div>
      </div>
    </div>
  );
};

export default NextLevelHorizontalViewer;
