import PouchDB from "pouchdb";

export const surveyDB = new PouchDB("surveyDB");

export const addSurvey = async (survey) => {
  try {
    const response = await surveyDB.put(survey);
    console.log("Survey added:", response);
    return response;
  } catch (error) {
    if (error.name === "conflict") {
      console.warn("Conflict detected, attempting to resolve...");

      try {
        const existing = await surveyDB.get(survey._id);
        survey._rev = existing._rev;

        const response = await surveyDB.put(survey);
        console.log("Survey updated after conflict:", response);
        return response;
      } catch (retryError) {
        console.error("Error resolving conflict:", retryError);
        throw retryError;
      }
    } else {
      console.error("Error adding survey:", error);
      throw error;
    }
  }
};

export const getSurvey = async (id) => {
  try {
    const question = await surveyDB.get(id);
    console.log("Survey question retrieved:", question);
    return question;
  } catch (error) {
    if (error.status === 404) {
      console.warn("Survey question not found:", id);
      return null;
    }
    console.error("Error retrieving survey question:", error);
    throw error;
  }
};

export const deleteSurvey = async (id) => {
  try {
    const question = await surveyDB.get(id);
    const response = await surveyDB.remove(question);
    console.log("Survey question deleted:", response);
    return response;
  } catch (error) {
    console.error("Error deleting survey question:", error);
    throw error;
  }
};
