import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { weightAtom } from "../../../atom/fatloss/weight-atom";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../../atom/coin/coin-atoms";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./WeightHistory.css";

const WeightHistory = () => {
  const navigate = useNavigate();
  const weightState = useRecoilValue(weightAtom);
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const animationCompleted = useRef(false); // Ensure animation runs only once
  const { weightHistory } = weightState;

  // Prepare data for Recharts
  const data = weightHistory.map((entry) => ({
    date: new Date(entry.weightTxDateTime).toLocaleDateString(),
    weight: entry.weightKg,
  }));

  // useEffect(() => {
  //   const today = new Date().toLocaleDateString();
  //   const firstDailyWeighing =
  //     weightHistory.filter(
  //       (entry) =>
  //         new Date(entry.weightDateTime).toLocaleDateString() === today,
  //     ).length === 1;

  //   if (firstDailyWeighing && !animationCompleted.current) {
  //     setCoinEarnedAmount(1);
  //     setCoinEarnedAnimation(true);

  //     animationCompleted.current = true;

  //     const animationDuration = 3000;
  //     setTimeout(() => {
  //       console.log("Coin animation completed.");
  //     }, animationDuration);
  //   }
  // }, [weightHistory, setCoinEarnedAmount, setCoinEarnedAnimation]);

  const handleBack = () => {
    navigate("/daily");
  };

  return (
    <div className="weight-history-container">
      <button onClick={handleBack} className="back-button">
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </button>
      <div className="graph-container">
        <h2>Weight History</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(value) => {
                const date = new Date(value);
                if (!isNaN(date)) {
                  let shortDate = value.replace("/2024", "");
                  shortDate = shortDate
                    .replace("/01", " Jan")
                    .replace("/02", " Feb")
                    .replace("/03", " Mar")
                    .replace("/04", " Apr")
                    .replace("/05", " May")
                    .replace("/06", " Jun")
                    .replace("/07", " Jul")
                    .replace("/08", " Aug")
                    .replace("/09", " Sep")
                    .replace("/10", " Oct")
                    .replace("/11", " Nov")
                    .replace("/12", " Dec");
                  return shortDate;
                }
                return value;
              }}
            />
            <YAxis domain={["dataMin - 1", "dataMax + 1"]} />
            <Tooltip />
            <Line type="monotone" dataKey="weight" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="calendar-container">
        <h2>Select a Date</h2>
        <Calendar />
      </div>
    </div>
  );
};

export default WeightHistory;
