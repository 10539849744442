import { configureStore, createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: "/",
  user: {
    email: null,
    name: null,
  },
  isProfileModalOpen: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
    clearStore: () => initialState,
    setProfileModalOpen: (state, action) => {
      state.isProfileModalOpen = action.payload;
    },
  },
});

// Export actions
export const { setCurrentPage, setUserInfo, clearStore, setProfileModalOpen } =
  appSlice.actions;

// Create store
const Store = configureStore({
  reducer: appSlice.reducer,
});

export default Store;
