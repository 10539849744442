import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import HeightPicker from "./HeightPicker";
import { userState } from "../../../atom/atoms";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { addSurveyQuestion } from "../../../store/pouch/survey-questions-pouch";
import { updateUserHeight } from "../../../store/pouch/user-pouch";

const QNum700 = () => {
  const [user, setUser] = useRecoilState(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const nicknameAnswer = useRecoilValue(questionAnswerState("100"));
  const [heightAnswer, setHeightAnswer] = useRecoilState(
    questionAnswerState("700"),
  );
  const [height, setHeight] = useState("");
  const [error, setError] = useState("");

  const nickname = nicknameAnswer?.surveyAnswerString || "";

  useEffect(() => {
    setHeight(heightAnswer?.surveyAnswerString || "");
  }, [heightAnswer]);

  const handleSaveHeight = async () => {
    const heightValue = parseInt(height, 10);
    if (isNaN(heightValue) || heightValue < 100 || heightValue > 299) {
      setError("Please provide a valid height.");
      return;
    }

    setError(""); // Clear error if height is valid

    const answerJson = {
      _id: `${user?.userEmail}-surveyQNum-700`,
      surveyQuestionNumber: "700",
      surveyAnswerString: height.toString(),
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    try {
      // Add survey question
      await addSurveyQuestion(answerJson);

      // Update user's height in PouchDB
      await updateUserHeight(user?.userEmail, heightValue);

      setHeightAnswer(answerJson);

      let userSurveyProgress = {
        currentQuestion: "800",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, userSurveyProgress });
      
      // Update local user state
      setUser(prevUser => ({
        ...prevUser,
        userHeightCm: heightValue
      }));

      setCurrentQuestion("800");
    } catch (error) {
      console.error("Error saving survey question or updating user height:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    setCurrentQuestion("600");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSaveHeight();
    }
  };

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {height && (
            <>
              {nickname}, what's your height📏?
            </>
          )}
          {!height && (
            <Typewriter
              options={{
                strings: [
                  `${nickname}, what's your height📏?`,
                ],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <HeightPicker
          height={height}
          setHeight={setHeight}
          handleKeyPress={handleKeyPress}
        />
      </div>
      {error && <p className="text-red-500 mt-2 text-center">{error}</p>}
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSaveHeight}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
        >
          Next
        </button>
      </div>
    </>
  );
};

export default QNum700;
