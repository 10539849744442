import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React } from 'react';
import { useNavigate } from 'react-router-dom';
const TrackBMI = () => {
    const navigate = useNavigate();
    const handleBack = () => navigate(-1);

    return(
     <>

        <button
        onClick={handleBack}
        className="mr-4 text-blue-600 hover:text-blue-800"
      >
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
      </button>
     <>
    track BMI
    </>
    </>)
    ;
};
export default TrackBMI;
