import { atom, selector } from "recoil";
import { localStorageEffect } from "../../util/localStorageEffect";

export const weightAtom = atom({
  key: "weightAtom",
  default: {
    versionId: 1,
    lastSynchronized: null,
    userEmail: "",
    weightHistory: [],
    weightTxDate: "",
  },
  effects: [localStorageEffect("weightAtom")],
});

export const updateWeightAtomSelector = selector({
  key: "updateWeightAtomSelector",
  get: ({ get }) => get(weightAtom),
  set: ({ set }, newValue) => {
    set(weightAtom, (prevState) => ({
      ...prevState,
      ...newValue,
    }));
  },
});
