import PouchDB from "pouchdb";

export const habitQDB = new PouchDB("habitQDB");
habitQDB
  .createIndex({
    index: {
      fields: ["userEmail"],
    },
  })
  .then(() => {
    console.log("Compound index created on userEmail ");
  })
  .catch((err) => {
    console.error("Error creating compound index:", err);
  });
habitQDB
  .createIndex({
    index: {
      fields: ["userEmail", "habitQuestionDate"],
    },
  })
  .then(() => {
    console.log("Compound index created on userEmail habitQuestionDate");
  })
  .catch((err) => {
    console.error("Error creating compound index:", err);
  });

export const addHabitQuestion = async (question) => {
  try {
    const response = await habitQDB.put(question);
    return response;
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const existingQuestion = await habitQDB.get(question._id);
        question._rev = existingQuestion._rev;

        const response = await habitQDB.put(question);
        return response;
      } catch (retryError) {
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};
export const getHabitQuestionsByUserEmailAndDateTimeRange = async (
  userEmail,
  startDateTime,
  endDateTime,
  // coinPurpose = null,
) => {
  try {
    let selector = {
      userEmail: userEmail,
      habitQuestionDate: {
        $gte: startDateTime,
        $lte: endDateTime,
      },
    };

    // if (coinPurpose) {
    //   selector.coinPurpose = coinPurpose;
    // }

    const result = await habitQDB.find({
      selector: selector,
      sort: [{ userEmail: "asc", habitQuestionDate: "desc" }],
    });

    console.log(
      "HabitQuestions retrieved for user within date range:",
      result.docs,
    );
    return result.docs;
  } catch (error) {
    console.error("Error retrieving habit for user within date range:", error);
    return [];
  }
};

export const getHabitQuestion = async (id) => {
  try {
    const question = await habitQDB.get(id);
    return question;
  } catch (error) {
    if (error.status === 404) {
      return null;
    }
    throw error;
  }
};

export const getHabitQuestionsByIds = async (ids) => {
  try {
    const result = await habitQDB.allDocs({
      include_docs: true,
      keys: ids,
    });
    return result.rows.filter((row) => row.doc).map((row) => row.doc);
  } catch (error) {
    console.error("Error fetching habit questions:", error);
    throw error;
  }
};

export const deleteHabitQuestion = async (id) => {
  try {
    const question = await habitQDB.get(id);
    const response = await habitQDB.remove(question);
    return response;
  } catch (error) {
    throw error;
  }
};
