import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  surveyCompletionState,
  currentQuestionState,
} from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import { addSurvey, getSurvey } from "../../../store/pouch/survey-pouch";
import { getLocalDateTime } from "../../../util/Util";
import { userState } from "../../../atom/atoms";
import { ClaimCoins } from "../../../util/CoinClaiming";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../../atom/coin/coin-atoms";
import {
  coinEarnedTodayState,
  coinTotalAmountState,
} from "../../../atom/coin/coin-today-atom";
import { addUserObj, updateUserInitialWeight } from "../../../store/pouch/user-pouch";
import { updateUserBMI } from "../../../store/pouch/user-pouch";

const AIPicker = () => {
  const [user, setUser] = useRecoilState(userState);
  const [aiAnswer, setAiAnswer] = useRecoilState(questionAnswerState("1000"));
  const setCurrentQuestion = useSetRecoilState(currentQuestionState);
  const setSurveyCompletion = useSetRecoilState(surveyCompletionState);
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);
  const setCoinTotalAmount = useSetRecoilState(coinTotalAmountState);
  const userInitialWeightKg = useRecoilValue(questionAnswerState("600"));
  const userHeightCm = useRecoilValue(questionAnswerState("500"));
  const navigate = useNavigate();

  const selectedHelpOptions = aiAnswer?.surveyAnswerList || [];
  const otherText = aiAnswer?.surveyAnswerString || "";

  const updateSurveyResponse = useCallback(
    (options, text, isComplete = false) => {
      const answerString = options.includes("Others") ? text : "";
      setAiAnswer({
        surveyQuestionNumber: "1000",
        surveyAnswerString: answerString,
        surveyAnswerList: options,
      });
    },
    [setAiAnswer],
  );

  const handleOptionToggle = useCallback(
    (option) => {
      let newOptions;
      if (option === "None") {
        newOptions = ["None"];
      } else {
        newOptions = selectedHelpOptions.filter((opt) => opt !== "None");
        if (selectedHelpOptions.includes(option)) {
          newOptions = newOptions.filter((opt) => opt !== option);
        } else {
          newOptions = [...newOptions, option];
        }
      }
      updateSurveyResponse(newOptions, otherText);
    },
    [selectedHelpOptions, otherText, updateSurveyResponse],
  );

  const handleOtherTextChange = useCallback(
    (e) => {
      const value = e.target.value;
      const regex = /^[a-zA-Z0-9\s.,()\-:''"'"]*$/;
      let filteredValue = value
        .split("")
        .filter((char) => regex.test(char))
        .join("");
      if (filteredValue.length > 1000) {
        filteredValue = filteredValue.substring(0, 1000);
      }
      updateSurveyResponse(selectedHelpOptions, filteredValue);
    },
    [selectedHelpOptions, updateSurveyResponse],
  );

  const validateAndCompleteSurvey = useCallback(() => {
    const asyncWrapper = async () => {
      if (selectedHelpOptions.length === 0) {
        return;
      }

      if (selectedHelpOptions.includes("Others") && otherText.trim() === "") {
        return;
      }

      updateSurveyResponse(selectedHelpOptions, otherText, true);

      let userSurveyProgress = {
        currentQuestion: "1000",
        isComplete: true,
        completionDateTime: new Date().toISOString(),
      };
      setSurveyCompletion(true);
      await addSurvey({ _id: user?.userEmail, userSurveyProgress });
      
      const userProgramStartDateTime = new Date().toISOString();
      
      // Update the user atom with the new userProgramStartDateTime
      setUser(prevUser => ({
        ...prevUser,
        userProgramStartDateTime: userProgramStartDateTime
      }));
      
      // Save the initial weight and calculate BMI
      if (userInitialWeightKg?.surveyAnswerString && userHeightCm?.surveyAnswerString) {
        const initialWeight = parseFloat(userInitialWeightKg.surveyAnswerString);
        const height = parseFloat(userHeightCm.surveyAnswerString);
        
        // Calculate BMI (weight in kg / (height in m)^2)
        let bmi = 0;
        if (height > 0) {
          bmi = initialWeight / ((height / 100) ** 2);
          bmi = parseFloat(bmi.toFixed(2)); // Round to 2 decimal places
        }

        await addUserObj({
          _id: user?.userEmail,
          userProgramStartDateTime: userProgramStartDateTime,
          userInitialWeightKg: initialWeight,
          userHeightCm: height,
        });

        // Update BMI
        if (bmi > 0) {
          await updateUserBMI(user?.userEmail, bmi);
        }
      } else {
        await addUserObj({
          _id: user?.userEmail,
          userProgramStartDateTime: userProgramStartDateTime,
        });
      }

      let claimCoinsArray = await ClaimCoins();
      let toEarn = claimCoinsArray?.reduce(
        (sum, transaction) => sum + transaction.coinsEarned,
        0,
      );
      setCoinEarnedAmount(toEarn);
      if (toEarn) {
        setCoinEarnedAnimation(true);
        setCoinEarnedToday(toEarn);
        setCoinTotalAmount(toEarn);
      }
      navigate("/daily");
    };
    asyncWrapper();
  }, [
    selectedHelpOptions,
    otherText,
    updateSurveyResponse,
    navigate,
    user?.userEmail,
    setUser,
    setCoinEarnedAmount,
    setCoinEarnedAnimation,
    setCoinEarnedToday,
    setCoinTotalAmount,
    userInitialWeightKg,
    userHeightCm,
  ]);

  const handleBack = useCallback(() => {
    setCurrentQuestion("900");
    navigate("/survey");
  }, [setCurrentQuestion, navigate]);

  const optionMapper = {
    "Track fat loss": "📉Track fat loss",
    "Dietary advice": "🥗Dietary advice",
    "Meal and recipes": "🍽️Meals/recipes",
    "Exercise planning": "🏋️Exercise plan",
    "Calorie estimation": "📊Calorie estimates",
    "Lifestyle advice": "🧘Lifestyle advice",
    Minigames: "🎮Minigames",
    None: "🚫None",
    Others: "📝Others",
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="text-center text-xl font-thin text-blue-600">
          What do you think
          <br />
          I, healthChat, <br />
          can help you with?
        </div>
      </div>
      <div className="flex flex-wrap justify-center mt-4 gap-2">
        {Object.keys(optionMapper).map((option) => (
          <button
            key={option}
            onClick={() => handleOptionToggle(option)}
            className={`px-4 py-2 rounded ${
              selectedHelpOptions.includes(option)
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-black"
            }`}
          >
            {optionMapper[option]}
          </button>
        ))}
      </div>
      {selectedHelpOptions.includes("Others") && (
        <div className="flex justify-center mt-4">
          <textarea
            value={otherText}
            onChange={handleOtherTextChange}
            placeholder="Please specify"
            className="border border-gray-300 rounded px-2 py-1 w-full"
            rows={4}
            maxLength={1000}
          />
        </div>
      )}
      <div className="flex justify-center mt-4">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        <button
          type="button"
          onClick={validateAndCompleteSurvey}
          className={`mt-2 px-4 py-2 rounded ${
            selectedHelpOptions.length === 0 ||
            (selectedHelpOptions.includes("Others") && otherText.trim() === "")
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
          disabled={
            selectedHelpOptions.length === 0 ||
            (selectedHelpOptions.includes("Others") && otherText.trim() === "")
          }
        >
          Finish
        </button>
      </div>
    </>
  );
};

export default AIPicker;
