const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_PATH = `/${process.env.REACT_APP_API_PATH}`;
const GOOGLE = process.env.REACT_APP_GOOGLE;
const META = process.env.REACT_APP_META;

const OAUTH_PATH = `${API_PATH}/oauth`;
const OLOGOUT = `${OAUTH_PATH}/logout`;
const OLOGIN = `${OAUTH_PATH}/login`;

const OTOKEN = `${OAUTH_PATH}/token`;

const OLGOOGLE = `${OLOGOUT}/${GOOGLE}`;
const OLINGOOGLE = `${OLOGIN}/${GOOGLE}`;
const RBOLGOOGLE = `${API_BASE_URL}${OLGOOGLE}`;
const RBOLINGOOGLE = `${API_BASE_URL}${OLINGOOGLE}`;
const OTGOOGLE = `${OTOKEN}/${GOOGLE}`;

const OLMETA = `${OLOGOUT}/${META}`;
const OLINMETA = `${OLOGIN}/${META}`;
const RBOLMETA = `${API_BASE_URL}${OLMETA}`;
const RBOLINMETA = `${API_BASE_URL}${OLINMETA}`;
const OTMETA = `${OTOKEN}/${META}`;

const EXT_PATH = `${API_PATH}/ext`;
const EPROFILE = `${EXT_PATH}/profile`;
const EPMETA = `${EPROFILE}/${META}`;

const SURVEY_PATH = `${API_PATH}/survey`;
const S_UPSERT = `${SURVEY_PATH}/upsert`;
const S_LOAD = `${SURVEY_PATH}/load`;
const MyEnv = {
  RBOLGOOGLE,
  RBOLINGOOGLE,
  OTGOOGLE,
  OLGOOGLE,
  RBOLMETA,
  RBOLINMETA,
  OTMETA,
  OLMETA,
  EPMETA,
  SURVEY_PATH,
  S_UPSERT,
  S_LOAD,
};

export default MyEnv;
