import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import "./MyProfile.css";
import { userState } from "../../atom/atoms";
import { useRecoilState } from "recoil";
import { updateUserNickname, updateUserPhone } from "../../store/pouch/user-pouch";

const MyProfile = () => {
  const [user, setUser] = useRecoilState(userState);
  const navigate = useNavigate();
  const location = useLocation();
  const [tempNickName, setTempNickName] = useState(user.userNickName);
  const [tempPhoneNumber, setTempPhoneNumber] = useState(user.userPhone);
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState({
    country: "Malaysia",
    code: "+60",
    flag: "🇲🇾",
  });
  const [hasChanges, setHasChanges] = useState(false);
  const [showUnsavedDataModal, setShowUnsavedDataModal] = useState(false);

  useEffect(() => {
    setHasChanges(
      tempNickName !== user.userNickName || tempPhoneNumber !== user.userPhone
    );
  }, [tempNickName, tempPhoneNumber, user.userNickName, user.userPhone]);

  const handleNickNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      setTempNickName(value);
    }
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.startsWith("06") || value.startsWith("60")) {
      value = value.slice(2);
    }
    if (value.startsWith("0") || value.startsWith("6")) {
      value = value.slice(1);
    }
    if (value.length <= 20) {
      setTempPhoneNumber(value);
    }
  };

  const handleSaveAndExit = () => {
    setIsShowConfirmation(true);
  };

  const handleConfirmSave = async () => {
    try {
      await updateUserNickname(user.userEmail, tempNickName);
      await updateUserPhone(user.userEmail, tempPhoneNumber, selectedCountryCode.code);
      
      setUser(prevUser => ({
        ...prevUser,
        userNickName: tempNickName,
        userPhone: tempPhoneNumber,
        userPhoneCountry: selectedCountryCode.code
      }));

      navigate(-1);
    } catch (error) {
      console.error("Error saving user data:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleCancelSave = () => {
    setIsShowConfirmation(false);
  };

  const handleBack = () => {
    if (hasChanges) {
      setShowUnsavedDataModal(true);
    } else {
      navigate(-1);
    }
  };

  const handleDiscardChanges = () => {
    setShowUnsavedDataModal(false);
    navigate(-1);
  };

  const handleCancelDiscard = () => {
    setShowUnsavedDataModal(false);
  };

  return (
    <div className="flex-col p-4">
      <div className="flex justify-between place-items-end">
        <button
          onClick={handleBack}
          className="text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 rounded-full p-2 mr-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="h-6 w-6" />
        </button>
        <button
          onClick={() => {
            navigate("/logout");
          }}
          className="text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 rounded-full p-2"
        >
          <FontAwesomeIcon icon={faDoorOpen} className="h-6 w-6" />
          <span className="ml-2 text-sm">Logout</span>
        </button>
      </div>

      <div className="flex flex-col">
        <div className="flex-center">
          <div className="w-24 h-24 rounded-full overflow-hidden mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/start2/00-laptop-phone.svg`}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
      <h2 className="text-l font-bold mb-4">{user.userName}</h2>
      <div className="mb-4">
        <label className="text-gray-700 text-sm font-bold mb-2">
          Nickname
        </label>
        <input
          type="text"
          value={tempNickName}
          onChange={handleNickNameChange}
          maxLength={15}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="text-gray-700 text-sm font-bold mb-2">
          Phone Number
        </label>
        <div className="flex rounded">
          <button
            type="button"
            className="flex items-center px-3 border border-r-0 rounded-l"
          >
            <span className="mr-2">{selectedCountryCode.flag}</span>
            <span>{selectedCountryCode.code}</span>
          </button>
          <input
            type="text"
            value={tempPhoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Your phone"
            className="shadow appearance-none border rounded-r w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            maxLength={20}
          />
        </div>
      </div>
      <button
        onClick={handleSaveAndExit}
        className={`mt-4 w-full font-bold py-2 px-4 rounded ${
          hasChanges
            ? "bg-blue-500 hover:bg-blue-700 text-white"
            : "bg-gray-300 text-gray-500 cursor-not-allowed"
        }`}
        disabled={!hasChanges}
      >
        Save & Exit
      </button>

      {isShowConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg">
            <h2 className="text-xl mb-4">Confirm Save</h2>
            <p className="mb-4">Are you sure you want to save these changes?</p>
            <div className="flex justify-end">
              <button
                onClick={handleConfirmSave}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Yes
              </button>
              <button
                onClick={handleCancelSave}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
              >
                No
              </button>
            </div>
          </div>
        </div>  
      )}

      {showUnsavedDataModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg">
            <h2 className="text-xl mb-4">Unsaved Changes</h2>
            <p className="mb-4">You have unsaved changes. Do you want to discard them?</p>
            <div className="flex justify-end">
              <button
                onClick={handleDiscardChanges}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Yes, Discard
              </button>
              <button
                onClick={handleCancelDiscard}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
