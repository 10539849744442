import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ResearchAccordionOffline from "./metrics/ResearchAccordionOffline";


const ResearchAdminOffline = ({ setPlayEarnCoin, setCoinsEarned }) => {
  const navigate = useNavigate();
  const [isResearchAdmin, setIsResearchAdmin] = useState(false);
  const [countdown, setCountdown] = useState(5);


  return (
    <div>
      {(
        <>
          <h1>Welcome Research Admin</h1>
          <ResearchAccordionOffline
            setPlayEarnCoin={setPlayEarnCoin}
            setCoinsEarned={setCoinsEarned}
          />
        </>
      ) }
    </div>
  );
};

export default ResearchAdminOffline;
