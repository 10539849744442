import React from "react";

const Privacy = () => {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
    },
    logo: {
      width: "100px",
      height: "auto",
    },
  };
  return (
    <div>
      <h1>Privacy Policy</h1>
      <div className="flex flex-col justify-end ">
        <div style={styles.container}>
          <img
            src={`${process.env.PUBLIC_URL}/thumbnail/pulogo.svg`}
            alt="Logo"
            style={styles.logo}
          />
          <img
            src={`${process.env.PUBLIC_URL}/thumbnail/simple-healthChat-logo.svg`}
            alt="HealthChat Logo"
            style={styles.logo}
          />
        </div>
      </div>
    </div>
  );
};

export default Privacy;
