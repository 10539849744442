import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addNavigationItem } from "../../store/pouch/navigation-pouch";
import "./Start.css";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  completedStartScreenState,
  stateOfIntroductionState,
  userState,
} from "../../atom/atoms";
import { surveyCompletionState } from "../../atom/survey/survey-atoms";
const Start = () => {
  const user = useRecoilValue(userState);
  const completedStartScreen = useRecoilValue(completedStartScreenState);
  const stateOfIntroduction = useRecoilValue(stateOfIntroductionState);
  const surveyCompletion = useRecoilValue(surveyCompletionState);
  const setCompleteStartScreen = useSetRecoilState(completedStartScreenState);
  const divideSpeed = 1;
  const [fadeStage, setFadeStage] = useState("fade-in");
  const [showLogo, setShowLogo] = useState(true);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showSwipeUp, setShowSwipeUp] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const navigate = useNavigate();
  const handleClick = async () => {
    const item = {
      _id: "isCompleteStartScreen",
      value: true,
    };
    try {
      await addNavigationItem(item);
    } catch (error) {
      console.log("Error adding navigation item:", error);
    }
    setCompleteStartScreen(true);

    setIsFadingOut(true);
    setTimeout(() => {
      if (!surveyCompletion && stateOfIntroduction !== "20") {
        navigate("/testchat");
      } else if (!user) {
        navigate("/signup-login");
      } else if (!surveyCompletion) {
        navigate("/survey");
      } else {
        navigate("/daily");
      }
    }, 1000);
  };

  useEffect(() => {
    const fadeInTimer = setTimeout(() => {
      setFadeStage("visible");
    }, 1000 / divideSpeed);

    const fadeOutTimer = setTimeout(() => {
      setShowLogo(false);
      setShowWelcome(true);
    }, 2000 / divideSpeed);

    const swipeUpTimer = setTimeout(() => {
      setShowSwipeUp(true);
    }, 4000 / divideSpeed);

    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(fadeOutTimer);
      clearTimeout(swipeUpTimer);
    };
  });

  return (
    <>
      <div className={`tailwind-reset container ${fadeStage}`}>
        {showLogo && (
          <div className="logo-container">
            <img
              src={`/healthChat.jpg`}
              alt="HealthChat Logo"
              className="logo"
            />
            <div className="stardust">
              <img src={`/thumbnail/stardust.svg`} alt="Stardust" />
            </div>
            <div className="logo-text">healthChat</div>
          </div>
        )}
        {!showLogo && (
          <div className="nature-fade-in background-svg image-container">
            <img
              className="fill-background"
              src={`/start2/01-welcome-nature.svg`}
              alt="Welcome Nature"
            />
          </div>
        )}
        {showWelcome && (
          <div className="welcome-text">
            <img src={`/start2/01.1-Welcome.svg`} alt="Welcome to healthChat" />
          </div>
        )}
        {showSwipeUp && (
          <>
            <div className="swipe-up-animation swipe-up-container">
              <span className="swipe-up-text">See more below</span>
              <span className="emoji-text">👇👇</span>
            </div>
          </>
        )}

        {!showLogo && (
          <div className="sun">
            <img src={`/start2/02.0-sun.svg`} alt="Sun" />
          </div>
        )}
      </div>
      {
        <>
          <div className="second-container">
            <div className="cluster">
              <img
                className="myname"
                src={`/start2/02.1-myname.jpg`}
                alt="healthChat"
              />
              <img
                className="mt-9"
                src={`/start2/02.2-i-am-healthchat.svg`}
                alt="i am healtch chat"
              />
              <img
                className=" hide-in-mobile"
                alt="healthChat"
                src={`/thumbnail/svg256.svg`}
              />
            </div>
            <div className="cluster">
              <img
                className="myname"
                alt="healthChat"
                src={`/start2/03.1-healthy-eating.jpg`}
              />
              <img
                src={`/start2/03.2-healthchat-full.svg`}
                alt="Healthy Eating And Lifestyle Transformation Hub"
              />
            </div>
            <div className="cluster">
              <img
                className="myname"
                alt="healthChat"
                src={`/start2/03.3-bot-exercise.jpg`}
              />
              <img
                className="myname"
                alt="healthChat"
                src="/start2/03.4-fat-loss.svg"
              />
            </div>
          </div>
          <div className="bottom-cluster">
            <button
              onClick={handleClick}
              className={`px-6 py-3 font-bold text-white rounded-lg shadow-md shadow-blue-500/15 transition-opacity duration-1000 ${
                isFadingOut ? "opacity-0" : "opacity-100"
              }  glowing-button  mt-5 mx-auto block`}
            >
              Get Started Now
            </button>
            <div
              className={`version-text`}
            >{`${process.env.REACT_APP_VERSION}`}</div>
          </div>
        </>
      }
    </>
  );
};

export default Start;
