import { atom, selector } from "recoil";
import { localStorageEffect } from "../../util/localStorageEffect";

export const lifestyleAtom = atom({
  key: "lifestyleAtom",
  default: {
    userEmail: null,
    lifestyleQuestionDate: null,
    lifestyleResponses: [],
  },
  effects: [localStorageEffect("lifestyleAtom")],
});

export const addLifestyleResponse = (setLifestyleAtom, responses) => {
  setLifestyleAtom((prevState) => ({
    ...prevState,
    // lifestyleResponses: [...prevState.lifestyleResponses, newResponse],
    lifestyleResponses: [...prevState.lifestyleResponses, responses],
  }));
};

export const updateLifestyleAtomSelector = selector({
  key: "updateLifestyleAtomSelector",
  get: ({ get }) => get(lifestyleAtom),
  set: ({ set }, newValue) => {
    set(lifestyleAtom, (prevState) => ({
      ...prevState,
      ...newValue,
    }));
  },
});
