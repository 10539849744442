import { atom, selector } from "recoil";
import { localStorageEffect } from "../../util/localStorageEffect";

export const habitAtom = atom({
  key: "habitAtom",
  default: {
    userEmail: null,
    habitQuestionDate: null,
    currentHabitQuestionNumber: 0,
    habitResponses: [],
  },
  effects: [localStorageEffect("habitAtom")],
});

export const addHabitResponse = (setHabitAtom, responses) => {
  setHabitAtom((prevState) => ({
    ...prevState,
    habitResponses: [...prevState.habitResponses, responses],
  }));
};

export const updateHabitAtomSelector = selector({
  key: "updateHabitAtomSelector",
  get: ({ get }) => get(habitAtom),
  set: ({ set }, newValue) => {
    set(habitAtom, (prevState) => ({
      ...prevState,
      ...newValue,
    }));
  },
});
