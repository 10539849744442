import React from "react";
import AIPicker from "./AIPicker";

const QNum1000 = () => {
  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <AIPicker />
    </>
  );
};

export default QNum1000;
