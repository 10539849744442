import axios from "axios";
import MyEnv from "../util/MyEnv";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const isTokenExists = (provider) => {
  return !!localStorage.getItem(provider + "Token");
};

export const post = (url, data) => {
  try {
    return apiClient.post(url, data);
  } catch (err) {
    console.log(err);
    return;
  }
};

export const get = (url, params = {}) => {
  try {
    return apiClient.get(url, { params });
  } catch (err) {
    console.log(err);
    return;
  }
};

const getAuthToken = (provider) => {
  return localStorage.getItem(provider + "Token");
};

const getWithAuth = (url, provider, params = {}) => {
  try {
    const token = getAuthToken(provider);
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    return apiClient.get(url, { params, headers });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      console.log("Unauthorized, logging out...");
    } else {
      console.log(err);
    }
    return;
  }
};

export const getData = (url) => {
  try {
    return get(url);
  } catch (err) {
    console.log(err);
    return;
  }
};

export const fetchMetaToken = (code) => {
  try {
    return get(MyEnv.OTMETA, { code });
  } catch (err) {
    console.log(err);
    return;
  }
};

// export const fetchUserInfoWithToken = (code) => {
//   try {
//     return get(MyEnv.EPMETA, { code });
//   } catch (err) {
//     console.log(err);
//     return;
//   }
// };

export const fetchGoogleToken = (code) => {
  try {
    return get(MyEnv.OTGOOGLE, { code });
  } catch (err) {
    console.log(err);
    return;
  }
};

export const logoutGoogle = async () => {
  try {
    return getWithAuth(MyEnv.OLGOOGLE, "google");
  } catch (err) {
    console.log(err);
    return;
  }
};
