import React from "react";
import LifestyleChanges from "./LifestyleChanges";

const QNum900 = () => {
  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <LifestyleChanges />
    </>
  );
};

export default QNum900;
