import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addUserObj } from "../../../store/pouch/user-pouch";
import { addSurvey, getSurvey } from "../../../store/pouch/survey-pouch";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentQuestionState,
  surveyCompletionState,
} from "../../../atom/survey/survey-atoms";
import { userState } from "../../../atom/atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";

const PumpData = () => {
  const setUser = useSetRecoilState(userState);
  const setCurrentQuestion = useSetRecoilState(currentQuestionState);
  const setSurveyComplete = useSetRecoilState(surveyCompletionState);
  const setQuestionAnswer100 = useSetRecoilState(questionAnswerState("100"));
  const setQuestionAnswer200 = useSetRecoilState(questionAnswerState("200"));
  const setQuestionAnswer300 = useSetRecoilState(questionAnswerState("300"));
  const setQuestionAnswer400 = useSetRecoilState(questionAnswerState("400"));
  const setQuestionAnswer500 = useSetRecoilState(questionAnswerState("500"));
  const setQuestionAnswer501 = useSetRecoilState(questionAnswerState("501"));
  const setQuestionAnswer600 = useSetRecoilState(questionAnswerState("600"));
  const setQuestionAnswer700 = useSetRecoilState(questionAnswerState("700"));
  const setQuestionAnswer800 = useSetRecoilState(questionAnswerState("800"));
  const setQuestionAnswer900 = useSetRecoilState(questionAnswerState("900"));
  const setQuestionAnswer1000 = useSetRecoilState(questionAnswerState("1000"));
  const [userData, setUserData] = useState({
    userEmail: "test0012@dev.com",
    userName: "Test User 0012",
    userNickName: "n TestUser 0012",
    isCompleteSurvey: false,
    isFillSurvey: false,
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = () => {
    const asyncWrapper = async () => {
      const testUserInfo = {
        userEmail: userData.userEmail,
        userName: userData.userName,
        userNickName: userData.userNickName,
        userPhoneCountry: null,
        userPhone: null,
        userRoles: [],
      };

      setUser(testUserInfo);
      let activeUser = { _id: "active", ...testUserInfo };
      addUserObj(activeUser);
      let userSurveyProgress = await getSurvey(activeUser?.userEmail);
      if (!userSurveyProgress && !userSurveyProgress?.value) {
        userSurveyProgress = {
          currentQuestion: "100",
          isComplete: userData.isCompleteSurvey,
          completionDateTime: userData.isCompleteSurvey
            ? new Date().toISOString()
            : null,
          earnedCoinAmount: 0,
          earnedCoinDatetime: null,
        };
      }
      addSurvey({ _id: activeUser?.userEmail, userSurveyProgress });
      setSurveyComplete(userData.isCompleteSurvey);
      if (userData.isFillSurvey) {
        setCurrentQuestion("1000");
        setQuestionAnswer100({
          surveyQuestionNumber: "100",
          surveyAnswerString: userData.userNickName,
          surveyAnswerList: [],
        });
        setQuestionAnswer200({
          surveyQuestionNumber: "200",
          surveyAnswerString: "female",
          surveyAnswerList: [],
        });
        setQuestionAnswer300({
          surveyQuestionNumber: "300",
          surveyAnswerString: "41-50",
          surveyAnswerList: [],
        });
        setQuestionAnswer400({
          surveyQuestionNumber: "400",
          surveyAnswerString: "Malay",
          surveyAnswerList: [],
        });
        setQuestionAnswer500({
          surveyQuestionNumber: "500",
          surveyAnswerString: "12233",
          surveyAnswerList: [],
        });
        setQuestionAnswer501({
          surveyQuestionNumber: "501",
          surveyAnswerString: "+60",
          surveyAnswerList: [],
        });
        setQuestionAnswer600({
          surveyQuestionNumber: "600",
          surveyAnswerString: "98",
          surveyAnswerList: [],
        });
        setQuestionAnswer700({
          surveyQuestionNumber: "700",
          surveyAnswerString: "170",
          surveyAnswerList: [],
        });
        setQuestionAnswer800({
          surveyQuestionNumber: "800",
          surveyAnswerString: "30",
          surveyAnswerList: [],
        });
        setQuestionAnswer900({
          surveyQuestionNumber: "900",
          surveyAnswerString: "",
          surveyAnswerList: [],
        });
        setQuestionAnswer1000({
          surveyQuestionNumber: "1000",
          surveyAnswerString: "",
          surveyAnswerList: ["None"],
        });
      } else {
        setCurrentQuestion("100");
      }
      if (userData.isCompleteSurvey) {
        navigate("/daily");
      } else navigate("/survey");
    };
    asyncWrapper();
  };

  const handlePumpRandomData = () => {
    console.log("Pump Random Data");
  };

  const handleGoToResearchAdminOffline = () => {
    navigate("/research-admin-offline");
  };

  return (
    <div className="test-custom-user pl-3 text-blue-400">
      <h2 className="text-2xl font-bold mb-4">Test Custom User</h2>
      <div className="mb-4">
        <label htmlFor="userEmail" className="block mb-1">
          Email:
        </label>
        <input
          type="email"
          id="userEmail"
          name="userEmail"
          value={userData.userEmail}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-blue-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="userName" className="block mb-1">
          Name:
        </label>
        <input
          type="text"
          id="userName"
          name="userName"
          value={userData.userName}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-blue-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="userNickName" className="block mb-1">
          Nickname:
        </label>
        <input
          type="text"
          id="userNickName"
          name="userNickName"
          value={userData.userNickName}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-blue-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
      <div className="mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="isCompleteSurvey"
            checked={userData.isCompleteSurvey}
            onChange={handleInputChange}
            className="mr-2 h-5 w-5 text-blue-400 focus:ring-blue-400 border-blue-400 rounded"
          />
          <span>Is Survey Complete</span>
        </label>
      </div>
      <div className="mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="isCompleteSurvey"
            checked={userData.isFillSurvey}
            onChange={handleInputChange}
            className="mr-2 h-5 w-5 text-blue-400 focus:ring-blue-400 border-blue-400 rounded"
          />
          <span>Is Fill Survey</span>
        </label>
      </div>

      <button
        onClick={handleSave}
        className="bg-blue-400 text-white px-4 py-2 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
      >
        Save and Go to Survey
      </button>

      {/* add button pump random data */}
      <button
        onClick={handlePumpRandomData}
        className="bg-blue-400 text-white px-4 py-2 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
      >
        Pump Random Data
      </button>
      {/* add buttom go to reasearc admin offline */}
      <button
        onClick={handleGoToResearchAdminOffline}
        className="bg-blue-400 text-white px-4 py-2 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
      >
        Go to Research Admin
      </button>
    </div>
  );
};

export default PumpData;
