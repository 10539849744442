import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ExerciseCalories } from "../../../util/ExerciseCalories";
import { nanoid } from "nanoid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { userState } from "../../../atom/atoms";
import {
  calorieAtom,
  calorieHistories,
} from "../../../atom/fatloss/calorie-atom";
import { ClaimCoins, MyCoinHistory } from "../../../util/CoinClaiming";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../../atom/coin/coin-atoms";
import { coinEarnedTodayState } from "../../../atom/coin/coin-today-atom";
import {
  getEndOfDayISOStringZ,
  getLocalDateOnly,
  getStartOfDayISOStringZ,
} from "../../../util/Util";
import { addCalorieItem } from "../../../store/pouch/calorie-pouch";

const Daily04Workout = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [exerciseData, setExerciseData] = useRecoilState(calorieHistories);
  const [selectedWorkout, setSelectedWorkout] = useState("");
  const [duration, setDuration] = useState("10");
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);

  const handleWorkoutSelect = (workout) => {
    setSelectedWorkout(workout);
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const asyncWrapper = async () => {
      if (handleValidation()) {
        const workout = ExerciseCalories.workoutTypes.find(
          (w) => w.name === selectedWorkout,
        );
        const calorieChange = workout.caloriesPerMinute * parseInt(duration);
        const newExercise = {
          _id: `${user?.userEmail}-calorie-${new Date().toISOString()}`,
          userEmail: user?.userEmail,
          calorieType: "exercise",
          calorieDescription: selectedWorkout,
          calorieRemarks: "",
          calorieDuration: parseInt(duration),
          calorieChange,
          calorieDate: getLocalDateOnly(),
          calorieDateTime: new Date().toISOString(),
        };
        setExerciseData(newExercise);
        await addCalorieItem(newExercise);
        let claimCoinsArray = await ClaimCoins();
        // debugger;
        let toEarn = claimCoinsArray?.reduce(
          (sum, transaction) => sum + transaction.coinsEarned,
          0,
        );
        setCoinEarnedAmount(toEarn);
        if (toEarn) {
          setCoinEarnedAnimation(true);
          let coinsTodayArray = await MyCoinHistory(
            user?.userEmail,
            getStartOfDayISOStringZ(),
            getEndOfDayISOStringZ(),
          );

          let coinToday = coinsTodayArray?.reduce(
            (sum, transaction) => sum + transaction.coinsEarned,
            0,
          );
          setCoinEarnedToday(coinToday);
        }

        navigate("/calorie");
      }
    };
    asyncWrapper();
  };

  const handleValidation = () => {
    let tempErrors = {};
    if (!selectedWorkout) {
      tempErrors.selectedWorkout = "Please select a workout";
    }

    if (!duration) {
      tempErrors.duration = "Duration is required";
    } else if (isNaN(duration) || duration <= 0) {
      tempErrors.duration = "Please enter a valid duration";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  return (
    <div className="p-4">
      <button
        onClick={() => navigate(-1)}
        className="mr-4 text-blue-600 hover:text-blue-800"
      >
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
      </button>
      <h2 className="text-2xl font-bold mb-4 text-blue-600">Record Workout</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            {selectedWorkout || "Select Workout"}
          </button>
          {errors.selectedWorkout && (
            <p className="text-red-500 text-sm mt-1">
              {errors.selectedWorkout}
            </p>
          )}
        </div>
        <div>
          <label className="text-blue-500 font-medium">Duration (minute)</label>
          <input
            type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            placeholder="Duration (minutes)"
            className="w-full px-4 py-2 text-blue-600 border border-blue-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          />
          {errors.duration && (
            <p className="text-red-500 text-sm mt-1">{errors.duration}</p>
          )}
        </div>
        <button
          disabled={!selectedWorkout}
          className={`w-full px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
            selectedWorkout
              ? "text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-500"
              : "text-gray-500 bg-gray-200 border-gray-300 cursor-not-allowed"
          }`}
        >
          Record Workout
        </button>
      </form>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg max-w-sm w-full">
            <h3 className="text-xl font-bold mb-4 text-blue-600">
              Select a Workout
            </h3>
            <div className="space-y-2">
              {ExerciseCalories.workoutTypes.map((workout) => (
                <button
                  key={workout.name}
                  onClick={() => handleWorkoutSelect(workout.name)}
                  className="w-full px-4 py-2 text-left text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded"
                >
                  <FontAwesomeIcon icon={workout.faIcon} /> {workout.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Daily04Workout;
