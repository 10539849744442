import React from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPlay,
  faTrophy,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../atom/atoms";
import BottomNavButton from "./BottomNavButton";

const CustomAppBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pn = location.pathname;
  const user = useRecoilValue(userState); // Access user state

  return (
    <>
      {pn !== "/start" &&
        pn !== "/survey" &&
        pn !== "/chatbot" &&
        pn !== "/lifestyle" &&
        pn !== "/testchat" && <div style={{ minHeight: "106px" }}></div>}
      {pn !== "/start" &&
        pn !== "/survey" &&
        pn !== "/chatbot" &&
        pn !== "/lifestyle" &&
        pn !== "/testchat" && (
          <AppBar
            position="fixed"
            style={{
              display: "block",
              top: "auto",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1100,
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Toolbar style={{ minHeight: "56px", padding: "0" }}>
              {" "}
              {/* Reduce height */}
              <div
                className="flex"
                style={{ width: "100%", justifyContent: "space-around" }}
              >
                {/* Home Button */}
                <BottomNavButton
                  icon={faHome}
                  label="Home"
                  onClick={() => navigate("/")}
                />

                {/* Start Button */}
                <BottomNavButton
                  icon={faPlay}
                  label="Start"
                  onClick={() => {
                    if (user) navigate("/daily");
                  }}
                  disabled={!user}
                />

                {/* Level Button */}
                <BottomNavButton
                  icon={faTrophy}
                  label="Level"
                  onClick={() => {
                    if (user) navigate("/next-level");
                  }}
                  disabled={!user}
                />

                {/* Profile/Login Button */}
                <BottomNavButton
                  icon={faUser}
                  label={user ? "Profile" : "Login"}
                  onClick={() => {
                    if (!user) {
                      navigate("/signup-login");
                    } else {
                      navigate("/myprofile");
                    }
                  }}
                />
              </div>
            </Toolbar>
          </AppBar>
        )}
    </>
  );
};

export default CustomAppBar;
