import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import WaistPicker from "./WaistPicker";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { userState } from "../../../atom/atoms";
import { addSurveyQuestion } from "../../../store/pouch/survey-questions-pouch";
import { updateUserWaist } from "../../../store/pouch/user-pouch";

const QNum800 = () => {
  const [user, setUser] = useRecoilState(userState);
  const setCurrentQuestion = useSetRecoilState(currentQuestionState);
  const nicknameAnswer = useRecoilValue(questionAnswerState("100"));
  const [waistAnswer, setWaistAnswer] = useRecoilState(
    questionAnswerState("800"),
  );
  const [waist, setWaist] = useState("");

  const nickname = nicknameAnswer?.surveyAnswerString || "";

  useEffect(() => {
    setWaist(waistAnswer?.surveyAnswerString || "");
  }, [waistAnswer]);

  const handleSaveWaist = async () => {
    if (waist === "" || parseInt(waist) < 10) {
      return;
    }

    const answerJson = {
      _id: `${user?.userEmail}-surveyQNum-800`,
      surveyQuestionNumber: "800",
      surveyAnswerString: waist,
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    try {
      // Add survey question
      await addSurveyQuestion(answerJson);

      // Update user's waist in PouchDB
      await updateUserWaist(user?.userEmail, parseInt(waist, 10));

      setWaistAnswer(answerJson);

      let userSurveyProgress = {
        currentQuestion: "900",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, userSurveyProgress });
      
      // Update local user state
      setUser(prevUser => ({
        ...prevUser,
        userWaistInch: parseInt(waist, 10)
      }));

      setCurrentQuestion("900");
    } catch (error) {
      console.error("Error saving survey question or updating user waist:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    setCurrentQuestion("700");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSaveWaist();
    }
  };

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src="chat2/05-cute-chatbot.jpg"
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {waist ? (
            <>
              {nickname}, could you please tell me <br /> your waist
              circumference,
              <br /> measured at belly button?
            </>
          ) : (
            <>
              <>
                {nickname}, could you please tell me <br />your waist circumference,
              </>
              <Typewriter
                options={{
                  strings: [
                    `<br/> measured at belly button?`,
                  ],
                  autoStart: true,
                  loop: false,
                  deleteSpeed: Infinity,
                  cursor: "",
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <WaistPicker
          waist={waist}
          setWaist={setWaist}
          handleKeyPress={handleKeyPress}
        />
      </div>
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSaveWaist}
          className={`mt-2 px-4 py-2 ${
            waist === "" || parseInt(waist) < 10
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500"
          } text-white rounded`}
          disabled={waist === "" || parseInt(waist) < 10}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default QNum800;
