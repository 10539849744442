import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { upLevelAtom } from '../../atom/up-level/up-level';
import './UpLevelMedal.css';
import { coinEarnedAnimationState } from '../../atom/coin/coin-atoms';

const UpLevelMedal = () => {
  const [upLevelState, setUpLevelState] = useRecoilState(upLevelAtom);
  const earnedCoinAnimation = useRecoilValue(coinEarnedAnimationState);
  const [playMedalAnimation, setPlayMedalAnimation] = useState(false);
    
  useEffect(() => {
    if (upLevelState.isPlayMedalAnimation) {
      if (earnedCoinAnimation) {
        setPlayMedalAnimation(false);
        const timer = setTimeout(() => {
          setPlayMedalAnimation(true);
        }, 6000);
        return () => clearTimeout(timer);
      } else {
        setPlayMedalAnimation(true);
      }

      const cleanupTimer = setTimeout(() => {
        setUpLevelState(prevState => ({
          ...prevState,
          isPlayMedalAnimation: false
        }));
        setPlayMedalAnimation(false);
      }, earnedCoinAnimation ? 11000 : 5000);

      return () => clearTimeout(cleanupTimer);
    }
  }, [upLevelState.isPlayMedalAnimation, earnedCoinAnimation, setUpLevelState]);

  return (
    <>
      {upLevelState.isPlayMedalAnimation && playMedalAnimation && (
        <div className="up-level-medal-overlay">
          <div className={`up-level-medal ${playMedalAnimation ? 'spin' : ''}`}>
            <img 
              src="/daily2/20-Level-Up.svg" 
              alt="Level Up Medal" 
              width="100" 
              height="193.537"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UpLevelMedal;
