import React, { useEffect } from "react";

const Terms = ({ setIsAllowedBackHome }) => {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
    },
    logo: {
      width: "100px",
      height: "auto",
    },
  };
  useEffect(() => {
    // Simulate an asynchronous operation
    // This could be an API call to check if the user is allowed back home
    const isAllowed = true; // Replace with your actual logic

    // Call the props function to set the state in the parent component
    setIsAllowedBackHome(isAllowed);
  }, [setIsAllowedBackHome]);
  return (
    <div>
      <h1>Terms of Service</h1>

      <div className="flex flex-col justify-end ">
        <div style={styles.container}>
          <img
            src={`${process.env.PUBLIC_URL}/thumbnail/pulogo.svg`}
            alt="Logo"
            style={styles.logo}
          />
          <img
            src={`${process.env.PUBLIC_URL}/thumbnail/simple-healthChat-logo.svg`}
            alt="HealthChat Logo"
            style={styles.logo}
          />
        </div>
      </div>
    </div>
  );
};

export default Terms;
