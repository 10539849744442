import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

const coinDB = new PouchDB("coinDB");
coinDB
  .createIndex({
    index: { fields: ["coinTxDateTime"] },
  })
  .then(() => {
    console.log("Index created on coinTxDateTime");
  })
  .catch((err) => {
    console.error("Error creating index:", err);
  });
coinDB
  .createIndex({
    index: {
      fields: ["userEmail"],
    },
  })
  .then(() => {
    console.log("Compound index created on userEmail ");
  })
  .catch((err) => {
    console.error("Error creating email index:", err);
  });
coinDB
  .createIndex({
    index: {
      fields: ["userEmail", "coinTxDateTime"],
    },
  })
  .then(() => {
    console.log("Compound index created on userEmail and coinTxDateTime");
  })
  .catch((err) => {
    console.error("Error creating compound index:", err);
  });
coinDB
  .createIndex({
    index: {
      fields: ["userEmail", "coinTxDateTime", "coinPurpose"],
    },
  })
  .then(() => {
    console.log(
      "Compound index created on userEmail, coinTxDateTime, and coinPurpose",
    );
  })
  .catch((err) => {
    console.error("Error creating compound index:", err);
  });
coinDB.viewCleanup();
// export const getAllCoins = async () => {
//   try {
//     const result = await coinDB.allDocs();
//     return result.docs;
//   } catch (error) {
//     console.error("Error retrieving all coins:", error);
//     return [];
//   }
// };

export const getCoinsByUserEmail = async (userEmail) => {
  try {
    let selector = {
      userEmail: userEmail,
      coinTxDateTime: { $exists: true },
    };
    const result = await coinDB.find({
      selector: selector,
      sort: [{ coinTxDateTime: "desc" }],
    });

    console.log("Coins retrieved for user email:", result.docs);
    return result.docs;
  } catch (error) {
    debugger;
    console.error("Error retrieving coins for user email:", error);
    return [];
  }
};

export const getCoinsByUserEmailAndDateTimeString = async (
  userEmail,
  startDateTime,
  endDateTime,
  coinPurpose = null,
) => {
  try {
    let selector = {
      userEmail: userEmail,
      coinTxDateTime: {
        $gte: startDateTime,
        $lte: endDateTime,
      },
    };

    if (coinPurpose) selector.coinPurpose = coinPurpose;

    const result = coinPurpose
      ? await coinDB.find({
          selector: selector,
          sort: [{ userEmail: "asc", coinTxDateTime: "desc" }],
        })
      : await coinDB.find({
          selector: selector,
          sort: [
            { userEmail: "asc", coinTxDateTime: "desc", coinPurpose: "asc" },
          ],
        });
    console.log("Coins retrieved for user within date range:", result.docs);
    return result.docs;
  } catch (error) {
    console.error("Error retrieving coins for user within date range:", error);
    return [];
  }
};

export const addCoinItem = async (item) => {
  try {
    const existingItem = await coinDB.get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await coinDB.put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await coinDB.get(item._id);
        item._rev = resolvedItem._rev;
        await coinDB.put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};

export const getCoinById = async (id) => {
  try {
    const byId = await coinDB.get(id);
    console.log("Coin byId retrieved:", byId);
    return byId;
  } catch (error) {
    if (error.status === 404) {
      console.warn("Coin byId not found:", id);
      return null;
    }
    console.log("Error retrieving coin:", error);
    return null;
  }
};

export const deleteCoinItem = async (id) => {
  try {
    const item = await coinDB.get(id);
    const response = await coinDB.remove(item);
    return response;
  } catch (error) {
    console.error("Error deleting coin item:", error);
    throw error;
  }
};

export const getTotalCoinsEarned = async (userEmail) => {
  try {
    const result = await coinDB.find({
      selector: {
        userEmail: userEmail,
        coinsEarned: { $gt: 0 }
      },
      fields: ['coinsEarned']
    });
    const totalCoins = result.docs.reduce((sum, doc) => sum + doc.coinsEarned, 0);
    return totalCoins;
  } catch (error) {
    console.error("Error retrieving total coins earned:", error);
    return 0;
  }
};

export const getTotalCoinsSpent = async (userEmail) => {
  try {
    const result = await coinDB.find({
      selector: {
        userEmail: userEmail,
        coinsSpent: { $gt: 0 }
      },
      fields: ['coinsSpent']
    });
    const totalCoins = result.docs.reduce((sum, doc) => sum + doc.coinsSpent, 0);
    return totalCoins;
  } catch (error) {
    console.error("Error retrieving total coins spent:", error);
    return 0;
  }
};

export default coinDB;
