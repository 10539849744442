import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../atom/coin/coin-atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faSquare,
  faCheckSquare,
  faComments,
  faCalculator,
  faTrophy,
  faChartLine,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import {
  getEndOfDayISOStringZ,
  getLocalDateOnly,
  getStartOfDayISOStringZ,
} from "../../util/Util";
import { calorieAtom } from "../../atom/fatloss/calorie-atom";
import { getCaloriesByUserEmailAndDateTimeString } from "../../store/pouch/calorie-pouch";
import { userState } from "../../atom/atoms";
import {
  getAllChat,
  getChatsByUserEmail,
  getChatsByUserEmailAndDateTimeString,
} from "../../store/pouch/chat-pouch";
import { getMealsByUserEmailAndEpoch } from "../../store/pouch/meal-pouch";

const DailyMore = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const exerciseState = useRecoilValue(calorieAtom);
  const [exerciseDoneToday, setExerciseDoneToday] = useState(false);
  const [chatDoneToday, setChatDoneToday] = useState(false);
  const [mealAddedToday, setMealAddedToday] = useState(false);
  const effectRan = useRef(false);

  const handleTrackExercise = () => {
    navigate("/exercise");
  };

  const handleTrackBMI = () => {
    navigate("/track-bmi");
  };
  const handleTalkToHealthChat = () => {
    navigate("/chatbot");
  };
  const handleCountCalories = () => {
    navigate("/mealsnap");
  };
  const handleViewNextLevel = () => {
    navigate("/next-level");
  };

  const handleAppointment = () => {
    navigate("/appointment-booking");
  };

  useEffect(() => {
    if (effectRan.current === false) {
      const asyncWrapper = async () => {
        let params = [
          user?.userEmail,
          getStartOfDayISOStringZ(),
          getEndOfDayISOStringZ(),
        ];
        let exerciseToday = await getCaloriesByUserEmailAndDateTimeString(
          user?.userEmail,
          getStartOfDayISOStringZ(),
          getEndOfDayISOStringZ(),
          "exercise",
        );
        setExerciseDoneToday(!!exerciseToday.length);

        let chatToday = await getChatsByUserEmailAndDateTimeString(...params);
        setChatDoneToday(!!chatToday.length);

        let mealToday = await getMealsByUserEmailAndEpoch(...params);
        setMealAddedToday(!!mealToday.length);
      };
      asyncWrapper();
    }
  }, [user?.userEmail]);

  return (
    <div className="flex flex-col items-center w-full mt-4">
      {/*<div className="w-full flex justify-start px-4 mb-4">
        <button
          onClick={handleBack}
          className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
        >
          <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        </button>
      </div>*/}
      <h2 className="text-2xl font-bold text-blue-500 mb-4">More mission</h2>
      <div className="shadow-lg shadow-gray-500 mb-4">
        <img
          src="/daily2/09.1-healthy-lifestyle-more.jpg"
          alt="More Mission"
          className=""
        />
      </div>
      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3">
          <FontAwesomeIcon
            icon={exerciseDoneToday ? faCheckSquare : faSquare}
            className={`w-full ${exerciseDoneToday ? "text-green-500" : "text-gray-400"}`}
            size="lg"
          />
        </div>
        <div className="w-3/4 flex items-center mb-3">
          <button
            onClick={handleTrackExercise}
            className={`w-full mr-3 py-3 rounded-lg transition-all duration-300 
              font-bold text-white shadow-md bg-blue-400 shadow-blue-800/35
            }`}
          >
            Track My Exercise
          </button>
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3">
          <FontAwesomeIcon
            icon={exerciseDoneToday ? faCheckSquare : faSquare}
            className={`w-full ${exerciseDoneToday ? "text-green-500" : "text-gray-400"}`}
            size="lg"
          />
        </div>
        <div className="w-3/4 flex items-center mb-3">
          <button
            onClick={handleTrackBMI}
            className={`w-full mr-3 py-3 rounded-lg transition-all duration-300 
              font-bold text-white shadow-md bg-blue-400 shadow-blue-800/35
            }`}
          >
            Track My BMI
          </button>
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3">
          <FontAwesomeIcon
            icon={chatDoneToday ? faCheckSquare : faSquare}
            className={`w-full ${chatDoneToday ? "text-green-500" : "text-gray-400"}`}
            size="lg"
          />
        </div>
        <div className="w-3/4 flex items-center mb-3">
          <button
            onClick={handleTalkToHealthChat}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3"
          >
            <FontAwesomeIcon icon={faComments} className="mr-2" />
            Talk to healthChat
          </button>
        </div>
      </div>

      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3">
          <FontAwesomeIcon
            icon={mealAddedToday ? faCheckSquare : faSquare}
            className={`w-full ${mealAddedToday ? "text-green-500" : "text-gray-400"}`}
            size="lg"
          />
        </div>
        <div className="w-3/4 flex items-center mb-3">
          <button
            onClick={handleCountCalories}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3"
          >
            <FontAwesomeIcon icon={faCalculator} className="mr-2" />
            Count Calories
          </button>
        </div>
      </div>

      {/* add button for predict fat loss */}
      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3">
        </div>
        <div className="w-3/4 flex items-center mb-3">
          <button
            onClick={() => navigate("/fat-loss")}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3"
          >
            <FontAwesomeIcon icon={faChartLine} className="mr-2" />
            Predict Fat Loss
          </button>
        </div>
      </div>

      {/* add button appointment for research */}
      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3"></div>
        <div className="w-3/4 flex items-center mb-3">
          <button
            onClick={handleAppointment}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3"
          >
            <FontAwesomeIcon icon={faCalendar} className="mr-2" />
            Appointment (Research)
          </button>
        </div>
      </div>









      <div className="flex w-full">
        <div className="w-1/4 flex items-center mb-3"></div>
        <div className="w-3/4 flex items-center mb-3">
          <button
            onClick={handleViewNextLevel}
            className="w-full py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mr-3"
          >
            <FontAwesomeIcon icon={faTrophy} className="mr-2" />
            View Next Level
          </button>
        </div>
      </div>

      {/*<button
        onClick={handleTestCoinClicked}
        className="px-6 py-3 font-bold text-white rounded-lg shadow-md bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 mb-3"
      >
        Test Coin
      </button>*/}
    </div>
  );
};

export default DailyMore;
