import { atom, selector } from "recoil";

export const coinTodayAtom = atom({
  key: "coinTodayAtom",
  default: {
    coinEarnedToday: 0,
    coinTotalAmount: 0,
    coinHistory: [],
    isDelete: false,
  },
});

// Selector for coinEarnedAnimation
export const coinEarnedTodayState = selector({
  key: "coinEarnedTodayState",
  get: ({ get }) => get(coinTodayAtom).coinEarnedToday,
  set: ({ set }, newValue) =>
    set(coinTodayAtom, (prevState) => ({
      ...prevState,
      coinEarnedToday: newValue,
    })),
});

// Selector for coinEarnedAmount
export const coinTotalAmountState = selector({
  key: "coinTotalAmountState",
  get: ({ get }) => get(coinTodayAtom).coinTotalAmount,
  set: ({ set }, newValue) =>
    set(coinTodayAtom, (prevState) => ({
      ...prevState,
      coinTotalAmount: newValue,
    })),
});
export const isDeleteCoinAtom = selector({
  key: "isDelete",
  get: ({ get }) => get(coinTodayAtom).isDelete,
  set: ({ set }, newValue) =>
    set(coinTodayAtom, (prevState) => ({
      ...prevState,
      isDelete: true,
      ...(newValue
        ? {
            coinEarnedToday: 0,
            coinTotalAmount: 0,
            coinHistory: [],
          }
        : {}),
    })),
});

export const coinHistories = selector({
  key: "coinHistory",
  get: ({ get }) => get(coinTodayAtom).coinHistory,
  set: ({ set }, newValue) =>
    set(coinTodayAtom, (prevState) => {
      const updatedHistory = [...prevState.coinHistory];
      newValue.forEach((newCoin) => {
        const index = updatedHistory.findIndex(
          (coin) => coin.coinId === newCoin.coinId,
        );
        if (index !== -1) {
          updatedHistory[index] = newCoin;
        } else {
          updatedHistory.push(newCoin);
        }
      });
      return {
        ...prevState,
        coinHistory: updatedHistory,
      };
    }),
});
