import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ResearchAccordion from "./metrics/ResearchAccordion";

const ResearchAdmin = ({ setPlayEarnCoin, setCoinsEarned }) => {
  const navigate = useNavigate();
  const [isResearchAdmin, setIsResearchAdmin] = useState(false);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const token = localStorage.getItem("internalToken");

    if (token) {
      try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map((c) => {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
        );

        const payload = JSON.parse(jsonPayload);

        if (payload.roles && payload.roles.includes("researchAdmin")) {
          setIsResearchAdmin(true);
        } else {
          setIsResearchAdmin(false);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        setIsResearchAdmin(false);
      }
    } else {
      setIsResearchAdmin(false);
    }
  }, []);

  useEffect(() => {
    if (!isResearchAdmin) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      if (countdown < 0) {
        clearInterval(interval);
        navigate("/");
      }

      return () => clearInterval(interval);
    }
  }, [isResearchAdmin, countdown, navigate]);

  return (
    <div>
      {isResearchAdmin ? (
        <>
          <h1>Welcome Research Admin</h1>
          <ResearchAccordion
            setPlayEarnCoin={setPlayEarnCoin}
            setCoinsEarned={setCoinsEarned}
          />
        </>
      ) : (
        <div>
          <span role="img" aria-label="forbidden">
            🚫
          </span>
          <p>
            You are not allowed on this page. Redirecting in {countdown}{" "}
            seconds...
          </p>
        </div>
      )}
    </div>
  );
};

export default ResearchAdmin;
