import React, { useState } from "react";
import Typewriter from "typewriter-effect";

const RandomGreeting = () => {
  const [randomGreeting, setRandomGreeting] = useState("");

  const greetings = [
    "What can I help?",
    "Can I help you?",
    "Let's see...",
    "How can I assist you?",
    "Feel free ...",
    "What you'd do today?",
    "I'm here to help. Ask me!",
  ];
  const [isFadingOut, setIsFadingOut] = useState(false);

  const getTimeOfDay = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours < 12) {
      return "morning";
    } else if (hours < 18) {
      return "afternoon";
    } else {
      return "evening";
    }
  };

  const showRandomGreeting = () => {
    let randomIndex = Math.floor(Math.random() * greetings.length);

    // Ensure the new greeting is different from the previous one
    if (randomGreeting === greetings[randomIndex]) {
      randomIndex = (randomIndex + 1) % greetings.length;
    }

    setRandomGreeting(greetings[randomIndex]); // Assign the new greeting to randomGreeting
    setIsFadingOut(true);
    setTimeout(() => {
      setIsFadingOut(false);
    }, 1000); // Adjust fade-out duration as needed
  };

  return (
    <div className="flex justify-center mt-4">
      <div className="text-center text-xl font-thin text-blue-600">
        <Typewriter
          options={{
            strings: [randomGreeting],
            autoStart: true,
            loop: false,
            deleteSpeed: Infinity,
            cursor: "",
          }}
        />
        <button
          onClick={showRandomGreeting}
          className={`px-6 py-3 font-bold text-white rounded-lg shadow-lg bg-blue-400 shadow-blue-800/35 transition-opacity duration-1000 ${
            isFadingOut ? "opacity-0" : "opacity-100"
          } `}
        >
          Good {getTimeOfDay()}
        </button>
      </div>
    </div>
  );
};

export default RandomGreeting;
