import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { iterateAllUsers } from "../../../util/ResearchUtil";
import { iterateAllSurveyQuestions } from "../../../store/pouch/survey-questions-pouch";
import { getAllUsersExceptActive } from "../../../store/pouch/user-pouch";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../../atom/coin/coin-atoms";
import { upLevelAtom } from "../../../atom/up-level/up-level";

const accordionItems = [
  "Admin Summary",
  "Charts",
  "Config",
  "Checkpoints",
  "Data",
  "Leaderboard",
  "Progress",
  "Reports",
  "Updates",
];

const ResearchAccordionOffline = () => {
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState([]);
  const [metricsData, setMetricsData] = useState(null);
  const [emails, setEmails] = useState([]);
  const [inputEmail, setInputEmail] = useState("");

  const setCoinsEarned = useSetRecoilState(coinEarnedAmountState);
  const setPlayEarnCoin = useSetRecoilState(coinEarnedAnimationState);
  const setUpLevelState = useSetRecoilState(upLevelAtom);

  const toggleAccordion = (item) => {
    setExpandedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item],
    );
  };

  const collapseAll = () => {
    setExpandedItems([]);
  };

  const refreshMetrics = async () => {
    try {
      const surveyResponses = await iterateAllSurveyQuestions("400");
      console.log(JSON.stringify(surveyResponses));

      let ethnicityCount = {
        Malay: 0,
        Chinese: 0,
        Indian: 0,
        Others: 0
      };

      surveyResponses.forEach(response => {
        const ethnicity = response.surveyAnswerString;
        if (["Malay", "Chinese", "Indian"].includes(ethnicity)) {
          ethnicityCount[ethnicity]++;
        } else {
          ethnicityCount.Others++;
        }
      });

      const allUsers = await getAllUsersExceptActive("active");
      debugger;
      // Initialize BMI count object
      const bmiCount = {
        underweight: 0,
        normal: 0,
        overweight: 0
      };

      // Categorize users based on BMI
      allUsers.forEach(userItem => {
        if (userItem.userBMI) {
          const bmi = parseFloat(userItem.userBMI);
          if (bmi < 18.5) {
            bmiCount.underweight++;
          } else if (bmi >= 18.5 && bmi < 25) {
            bmiCount.normal++;
          } else {
            bmiCount.overweight++;
          }
        }
      });

      // Create BMI metrics data
      const bmiMetrics = [
        { bmiCategory: "< 18.5", bmiUsers: bmiCount.underweight, bmiTargetUsers: 80 },
        { bmiCategory: "18.5 - 24.9", bmiUsers: bmiCount.normal, bmiTargetUsers: 80 },
        { bmiCategory: "≥ 25", bmiUsers: bmiCount.overweight, bmiTargetUsers: 80 },
      ];

      // Fetch responses for question 300 (age group)
      const ageGroupResponses = await iterateAllSurveyQuestions("300");

      // Initialize age group count object
      const ageGroupCount = {
        "21-30": 0,
        "31-40": 0,
        "41-50": 0,
        "51-60": 0,
        "other": 0
      };

      // Categorize users based on age group responses
      ageGroupResponses.forEach(response => {
        const ageGroup = response.surveyAnswerString;
        if (ageGroupCount.hasOwnProperty(ageGroup)) {
          ageGroupCount[ageGroup]++;
        } else {
          ageGroupCount.other++;
        }
      });

      // Create age group metrics data
      const ageGroupMetrics = [
        { ageGroup: "21-30", users: ageGroupCount["21-30"], targetUsers: 60 },
        { ageGroup: "31-40", users: ageGroupCount["31-40"], targetUsers: 60 },
        { ageGroup: "41-50", users: ageGroupCount["41-50"], targetUsers: 60 },
        { ageGroup: "51-60", users: ageGroupCount["51-60"], targetUsers: 60 },
      ];

      // Fetch responses for question 200 (gender)
      const genderResponses = await iterateAllSurveyQuestions("200");

      // Initialize gender count object
      const genderCount = {
        Male: 0,
        Female: 0,
        Other: 0
      };

      // Categorize users based on gender responses
      genderResponses.forEach(response => {
        const gender = response.surveyAnswerString.toLowerCase();
        if (gender === "male") {
          genderCount.Male++;
        } else if (gender === "female") {
          genderCount.Female++;
        } else {
          genderCount.Other++;
        }
      });

      // Create gender metrics data
      const genderMetrics = [
        { gender: "Male", users: genderCount.Male, targetUsers: 120 },
        { gender: "Female", users: genderCount.Female, targetUsers: 120 },
        // { gender: "Other", users: genderCount.Other, targetUsers: 0 },
      ];

      setMetricsData((prevData) => ({
        ...prevData,
        ethnicityMetrics: [
          { ethnicityName: "Malay", ethnicityUsersSignedUp: ethnicityCount.Malay, ethnicityTargetUsers: 144 },
          { ethnicityName: "Chinese", ethnicityUsersSignedUp: ethnicityCount.Chinese, ethnicityTargetUsers: 48 },
          { ethnicityName: "Indian", ethnicityUsersSignedUp: ethnicityCount.Indian, ethnicityTargetUsers: 24 },
          { ethnicityName: "Others", ethnicityUsersSignedUp: ethnicityCount.Others, ethnicityTargetUsers: 24 },
        ],
        bmiMetrics: bmiMetrics,
        ageGroupMetrics: ageGroupMetrics,
        genderMetrics: genderMetrics,
      }));
    } catch (error) {
      console.error("Failed to refresh metrics:", error);
    }
  };

  const handleTestCoinAnimation = () => {
    setCoinsEarned(8);
    setPlayEarnCoin(true);
  };

  const handleTestMedalAnimation = () => {
    setUpLevelState(prevState => ({
      ...prevState,
      isPlayMedalAnimation: true
    }));
    console.log("Test Medal Animation");
  };

  const handleTestCoinAndMedal = () => {
    setCoinsEarned(8);
    setPlayEarnCoin(true);
    setUpLevelState(prevState => ({
      ...prevState,
      isPlayMedalAnimation: true
    }));
    console.log("Test Coin & Medal Animation");
  };

  const handleInputChange = (e) => {
    setInputEmail(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && inputEmail.trim()) {
      setEmails([...emails, inputEmail.trim()]);
      setInputEmail("");
    }
  };

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  return (
    <div>
      {accordionItems.map((item) => (
        <div
          key={item}
          style={{
            backgroundColor: "#3b82f6",
            color: "white",
            margin: "5px 0",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <div
            onClick={() => toggleAccordion(item)}
            style={{ cursor: "pointer" }}
          >
            {item}
          </div>
          {expandedItems.includes(item) && (
            <div
              style={{
                padding: "10px",
                backgroundColor: "#1e3a8a",
                borderRadius: "5px",
              }}
            >
              {item === "Admin Summary" && (
                <div>
                  <button
                    onClick={refreshMetrics}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    Refresh Metrics
                  </button>

                  {metricsData && metricsData.ethnicityMetrics && metricsData.ethnicityMetrics.length > 0 && (
                    <div style={{ width: '100%', height: 300, backgroundColor: 'white', padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
                      <h3 className="text-blue-400">Ethnicity Distribution</h3>
                      <ResponsiveContainer>
                        <BarChart data={metricsData.ethnicityMetrics} style={{ backgroundColor: 'white' }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="ethnicityName" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="ethnicityUsersSignedUp" fill="#8884d8" name="Users Signed Up" />
                          <Bar dataKey="ethnicityTargetUsers" fill="#82ca9d" name="Target Users" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {metricsData && metricsData.bmiMetrics && metricsData.bmiMetrics.length > 0 && (
                    <div style={{ width: '100%', height: 300, backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
                      <h3 className="text-blue-400">BMI Distribution</h3>
                      <ResponsiveContainer>
                        <BarChart data={metricsData.bmiMetrics} style={{ backgroundColor: 'white' }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="bmiCategory" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="bmiUsers" fill="#8884d8" name="Current Users" />
                          <Bar dataKey="bmiTargetUsers" fill="#82ca9d" name="Target Users" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {metricsData && metricsData.ageGroupMetrics && metricsData.ageGroupMetrics.length > 0 && (
                    <div className="mt-3" style={{ width: '100%', height: 300, backgroundColor: 'white', padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
                      <h3 className="text-blue-400">Age Group Distribution</h3>
                      <ResponsiveContainer>
                        <BarChart data={metricsData.ageGroupMetrics} style={{ backgroundColor: 'white' }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="ageGroup" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="users" fill="#8884d8" name="Current Users" />
                          <Bar dataKey="targetUsers" fill="#82ca9d" name="Target Users" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  )}

                  {metricsData && metricsData.genderMetrics && metricsData.genderMetrics.length > 0 && (
                    <div style={{ width: '100%', height: 300, backgroundColor: 'white', padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
                      <h3 className="text-blue-400">Gender Distribution</h3>
                      <ResponsiveContainer>
                        <BarChart data={metricsData.genderMetrics} style={{ backgroundColor: 'white' }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="gender" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="users" fill="#8884d8" name="Current Users" />
                          <Bar dataKey="targetUsers" fill="#82ca9d" name="Target Users" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                </div>
              )}
              {item === "Updates" && (
                <div>
                  <h3 className="text-blue-400">Bulk Soft Delete User Profiles</h3>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom: "10px",
                    }}
                  >
                    {emails.map((email, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "#4b5563",
                          color: "white",
                          padding: "5px 10px",
                          margin: "2px",
                          borderRadius: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {email}
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => removeEmail(email)}
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                        />
                      </div>
                    ))}
                  </div>
                  <input
                    type="email"
                    value={inputEmail}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    placeholder="Enter email addresses"
                    style={{
                      width: "100%",
                      padding: "8px",
                      marginBottom: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      color: "blue",
                    }}
                  />
                  <button
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#dc2626",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Bulk Soft Delete
                  </button>
                </div>
              )}

              {/* Appointment Slot Config */}
              {item === "Config" && (
                <>
                <div>
                  <h3 className="text-blue-400">Appointment Slot Config</h3>
                  <p>Configure your appointment slots here.</p>
                </div>
                <button
                  onClick={() => navigate("/appointment-offline")}
                  style={{
                    padding: "8px 16px",
                    backgroundColor: "#4caf50",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                >
                  Appointment Slot Config
                </button>
                </>
              )}

              {item === "Checkpoints" && (
                <div>
                  <h3 className="text-blue-400">Test Animations</h3>
                  <button
                    onClick={handleTestCoinAnimation}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Test Coin Animation
                  </button>
                  <button
                    onClick={handleTestMedalAnimation}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    Test Medal Animation
                  </button>
                  <button
                    onClick={handleTestCoinAndMedal}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    Test Coin & Medal
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          cursor: "pointer",
          backgroundColor: "#93c5fd",
          borderRadius: "50%",
          padding: "10px",
        }}
        onClick={collapseAll}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </div>
    </div>
  );
};

export default ResearchAccordionOffline;
