import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { useRecoilState } from "recoil";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";

const WaistPicker = ({ waist, setWaist, handleKeyPress }) => {
  const [waistAnswer, setWaistAnswer] = useRecoilState(
    questionAnswerState("800"),
  );
  const [inputClass, setInputClass] = useState("input-unfocused");

  useEffect(() => {
    if (waistAnswer?.surveyAnswerString) {
      setWaist(parseInt(waistAnswer.surveyAnswerString, 10));
    }
  }, [waistAnswer, setWaist]);

  const handleWaistChange = (value) => {
    if (typeof value === "undefined" || value === null || value === "") {
      setWaist("");
      updateSurveyAnswer("");
      return;
    }
    const parsedValue = parseInt(value);
    if (!isNaN(parsedValue) && parsedValue < 100) {
      setWaist(parsedValue);
      updateSurveyAnswer(parsedValue.toString());
    }
  };

  const updateSurveyAnswer = (answerString) => {
    setWaistAnswer({
      surveyQuestionNumber: "800",
      surveyAnswerString: answerString,
      surveyAnswerList: [],
    });
  };

  return (
    <div className="flex justify-center items-center">
      <CurrencyInput
        className={`text-center ${inputClass}`}
        style={{
          fontSize: "3rem",
          width: "55vw",
          height: "4rem",
        }}
        value={waist}
        decimalsLimit={0}
        onValueChange={handleWaistChange}
        allowNegativeValue={false}
        disableGroupSeparators={true}
        placeholder="0"
        onFocus={() => setInputClass("input-focused")}
        onBlur={() => setInputClass("input-unfocused")}
        onKeyPress={handleKeyPress}
      />
      <span className="ml-2" style={{ fontSize: "3rem" }}>
        inch
      </span>
    </div>
  );
};

export default WaistPicker;
