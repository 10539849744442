import React, { useMemo, useState } from "react";
import { FixedSizeList as List } from "react-window";
import { useRecoilValue } from "recoil";
import { coinTodayAtom } from "../../../../atom/coin/coin-today-atom";

const CoinHistory = () => {
  const [coinHistoryArray, setCoinHistoryArray] = useState(() => {
    return [
      {
        coinPurpose: "SURVEY COMPLETE",
        coinsEarned: 10,
        coinTimeCluster: "Today",
        coinTxDisplayTime: "Just now",
      },
      {
        coinPurpose: "DAILY LIFESTYLE",
        coinsEarned: 10,
        coinTimeCluster: "Today",
        coinTxDisplayTime: "1 hour ago",
      },

      {
        coinPurpose: "DAILY WEIGH",
        coinsEarned: 10,
        coinTimeCluster: "Yesterday",
        coinTxDisplayTime: "1 day ago",
      },

      {
        coinPurpose: "DAILY WEIGH",
        coinsEarned: 10,
        coinTimeCluster: "Earlier",
        coinTxDisplayTime: "2 days ago",
      },
    ];
  });
  const coinTodayHistory = useRecoilValue(coinTodayAtom);

  const groupedHistory = useMemo(() => {
    if (!coinTodayHistory?.coinHistory) return {};

    // Sort the coinHistory array by coinTxDateTime in descending order
    const sortedHistory = [...coinTodayHistory.coinHistory].sort((a, b) => 
      new Date(b.coinTxDateTime) - new Date(a.coinTxDateTime)
    );

    // Perform the reduce operation on the sorted array
    return sortedHistory.reduce((acc, item) => {
      if (!acc[item.coinTimeCluster]) {
        acc[item.coinTimeCluster] = [];
      }
      acc[item.coinTimeCluster].push(item);
      return acc;
    }, {});
  }, [coinTodayHistory]);

  const flattenedHistory = useMemo(() => {
    return Object.entries(groupedHistory).flatMap(([cluster, items]) => [
      { type: "header", cluster },
      ...items.map((item) => ({ type: "item", ...item })),
    ]);
  }, [groupedHistory]);

  const Row = ({ index, style }) => {
    const item = flattenedHistory[index];
    if (item.type === "header") {
      return (
        <div style={style} className="font-bold text-lg py-2">
          {item.cluster}
        </div>
      );
    } else {
      return (
        <div style={style} className="flex items-center py-2">
          <div className="w-40 mr-4 overflow-hidden whitespace-nowrap text-ellipsis">
            {item.coinPurpose}
          </div>
          <div className="flex-1 flex justify-between items-center">
            <span className="mr-4">{item.coinsEarned} coins</span>
            <span className="text-sm text-gray-500">
              {item.coinTxDisplayTime}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="ml-3 mr-3">
        <List
          height={400}
          itemCount={flattenedHistory.length}
          itemSize={50}
          width="100%"
          initialScrollOffset={0}
        >
          {Row}
        </List>
      </div>
    </>
  );
};

export default CoinHistory;
