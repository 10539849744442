import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faBolt } from "@fortawesome/free-solid-svg-icons";
import { useRecoilValue } from "recoil";
import { userState } from "../../../atom/atoms";
import { getLifestyleQuestionsByUserEmailAndDateTimeRange } from "../../../store/pouch/lifestyle-pouch";
import "./DailyPredictFatLoss.css";
import { getEndOfDayISOStringZ, getStartOfDayISOStringZ } from "../../../util/Util";
import { getHabitQuestionsByUserEmailAndDateTimeRange } from "../../../store/pouch/habit-pouch";

const DailyPredictFatLoss = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const [lifestyleScore, setLifestyleScore] = useState(0);
  const [batteryFillWidth, setBatteryFillWidth] = useState("0%");

  useEffect(() => {
    const fetchLifestyleScore = async () => {

      const lifestyleQuestions = await getLifestyleQuestionsByUserEmailAndDateTimeRange(
        user?.userEmail,
        getStartOfDayISOStringZ(),
        getEndOfDayISOStringZ()
      );
      const habitQuestions = await getHabitQuestionsByUserEmailAndDateTimeRange(
        user?.userEmail,
        getStartOfDayISOStringZ(),
        getEndOfDayISOStringZ()
      );
      const score = lifestyleQuestions.reduce((total, question) => {
        return total + (question.lifestyleQuestionResponse === "yes" ? 5 : 0);
      }, 0) + habitQuestions.reduce((total, question) => {
        return total + (question.answer === "no" ? 5 : 0);
      }, 0);

      setLifestyleScore(score);
      setBatteryFillWidth(`${Math.min(score, 100)}%`);
    };

    fetchLifestyleScore();
  }, [user.userEmail]);

  const handleBack = () => {
    navigate("/daily");
  };

  return (
    <>
      <div className="daily-predict-fatloss-container">
        <button onClick={handleBack} className="back-button">
          <FontAwesomeIcon icon={faChevronLeft} /> Back
        </button>
        <div className="battery-container">
          <div className="battery-body">
            <div className="zinc-cylinder">
              <FontAwesomeIcon icon={faBolt} className="lightning-bolt" />
            </div>
            <div className="battery-fill" style={{width: batteryFillWidth}}></div>
          </div>
          <div className="battery-tip"></div>
        </div>
        <div className="battery-label">
          <span>0</span>
          <span>{lifestyleScore}%</span>
          <span>100</span>
        </div>
        {/* <button className="font-bold text-xl px-8 py-5 power-up-button mt-3 bg-blue-400 text-white rounded-xl shadow-md shadow-blue-800/35"> */}
          <span className="mt-5 text-blue-500 font-bold text-2xl">
            To Power Up Fat Loss, Do More Missions
          </span>
        {/* </button> */}
      </div>
    </>
  );
};

export default DailyPredictFatLoss;
