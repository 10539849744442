import PouchDB from "pouchdb";

export const surveyQDB = new PouchDB("surveyQuestionsDB");

export const addSurveyQuestion = async (question) => {
  try {
    const response = await surveyQDB.put(question);
    return response;
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const existingQuestion = await surveyQDB.get(question._id);
        
        const updatedQuestion = Object.isExtensible(question) 
          ? { ...question, _rev: existingQuestion._rev } 
          : Object.assign({}, question, { _rev: existingQuestion._rev });

        const response = await surveyQDB.put(updatedQuestion);
        return response;
      } catch (retryError) {
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};


export const getSurveyQuestion = async (id) => {
  try {
    const question = await surveyQDB.get(id);
    return question;
  } catch (error) {
    if (error.status === 404) {
      return null;
    }
    throw error;
  }
};

export const deleteSurveyQuestion = async (id) => {
  try {
    const question = await surveyQDB.get(id);
    const response = await surveyQDB.remove(question);
    return response;
  } catch (error) {
    throw error;
  }
};

export const iterateAllSurveyQuestions = async (filterQuestionNum) => {
  try {
    const allDocs = await surveyQDB.allDocs({ include_docs: true });
    const filteredQuestions = [];

    for (const row of allDocs.rows) {
      const question = row.doc;
      if (!filterQuestionNum || question.surveyQuestionNumber === filterQuestionNum) {
        filteredQuestions.push(question);
      }
    }

    return filteredQuestions;
  } catch (error) {
    console.error("Error iterating survey questions:", error);
    throw error;
  }
};
