import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  MinChatUiProvider,
  MainContainer,
  MessageInput,
  MessageContainer,
  MessageList,
  MessageHeader,
} from "@minchat/react-chat-ui";

import { formatTime, getArrayByStateOfIntroduction } from "../../util/Util";
import {
  addNavigationItem,
  getNavigationItems,
} from "../../store/pouch/navigation-pouch";
import "./TestChat.css";
import { userState } from "../../atom/atoms";
import { useRecoilValue } from "recoil";
import { surveyCompletionState } from "../../atom/survey/survey-atoms";

const TestChat = ({ setIsAllowedBackHome }) => {
  const user = useRecoilValue(userState);
  const surveyCompletion = useRecoilValue(surveyCompletionState);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showSuggestedReply, setShowSuggestedReply] = useState(false);
  const [suggestedReplyText, setSuggestedReplyText] = useState("Tell me more");
  const navigate = useNavigate();
  const timeoutRef = useRef(null);
  const effectRan = useRef(false);

  const handleDefaultMessage = useCallback(
    async (text, isTypingAllowed = true) => {
      const items = await getNavigationItems();
      const item = items.find((i) => i._id === "stateOfIntroduction");
      let savedState = item?.value || "0";

      if (savedState === "10") {
        savedState = "20";
        addNavigationItem({
          _id: "stateOfIntroduction",
          value: savedState,
        });
        setMessages((prevMessages) => [
          ...prevMessages,
          ...(text && text.trim()
            ? [{ text, user: { id: "user01", name: "User" } }]
            : []),
          getArrayByStateOfIntroduction(savedState + "a"),
        ]);
        setShowSuggestedReply(false);
        if (isTypingAllowed) setIsTyping(true);
        setTimeout(
          () => {
            setIsTyping(false);
            setMessages((prevMessages) => [
              ...prevMessages,
              getArrayByStateOfIntroduction(savedState + "b"),
            ]);

            setTimeout(
              () => {
                setMessages((prevMessages) => [
                  ...prevMessages,
                  getArrayByStateOfIntroduction(savedState + "c"),
                ]);

                setTimeout(
                  () => {
                    setMessages((prevMessages) => [
                      ...prevMessages,
                      getArrayByStateOfIntroduction(savedState + "d"),
                    ]);
                    setShowSuggestedReply(true);
                    setSuggestedReplyText("Continue with Survey");
                  },
                  isTypingAllowed ? 1000 : 0,
                );
              },
              isTypingAllowed ? 1000 : 0,
            );
          },
          isTypingAllowed ? 2000 : 0,
        );
      } else if (savedState === "20") {
        savedState = "20";
        addNavigationItem({
          _id: "stateOfIntroduction",
          value: savedState,
        });
        if (!user) {
          navigate("/signup-login");
        } else if (!surveyCompletion) {
          navigate("/survey");
        } else {
          navigate("/daily");
        }
      }
    },
    [navigate],
  );

  const handleSuggestedReply = useCallback(
    (isTypingAllowed = true) => {
      handleDefaultMessage(null, isTypingAllowed);
    },
    [handleDefaultMessage],
  );

  useEffect(() => {
    // if (effectRan.current) return;
    // effectRan.current = true;

    const initializeChat = async () => {
      const items = await getNavigationItems();
      const item = items.find((i) => i._id === "stateOfIntroduction");
      let savedState = item?.value || "0";

      if (!savedState || savedState === "0") {
        setIsTyping(true);
        savedState = "0";
        addNavigationItem({
          _id: "stateOfIntroduction",
          value: savedState,
        });
      }

      setIsAllowedBackHome(false);

      timeoutRef.current = setTimeout(
        () => {
          setIsTyping(false);
          if (savedState === "0") {
            savedState = "10";

            addNavigationItem({
              _id: "stateOfIntroduction",
              value: savedState,
            });
          }
          const initialMessages = [getArrayByStateOfIntroduction("10")];
          setMessages(initialMessages);
          setShowSuggestedReply(savedState === "10");

          if (savedState >= "20") {
            setSuggestedReplyText("Tell me more");
            handleSuggestedReply(false);
          }
        },
        savedState === "0" ? 2000 : 0,
      );
    };

    initializeChat();

    return () => {};
  }, [handleSuggestedReply, setIsAllowedBackHome]);

  return (
    <>
      <div className="blue-circle"></div>
      <MinChatUiProvider theme="#6ea9d7">
        <MainContainer style={{ height: "100vh", width: "100vw" }}>
          <MessageContainer>
            <MessageHeader />
            {messages.length === 0 ? (
              <div className="chatbot-image-container">
                <img
                  src="chat2/05-cute-chatbot.jpg"
                  alt="Chatbot"
                  className="chatbot-image"
                />
              </div>
            ) : (
              <MessageList
                currentUserId="user01"
                messages={messages.map((msg) => ({
                  ...msg,
                  user: {
                    ...msg.user,
                    name: `${msg.user?.name ?? ""} (${formatTime(msg.timestamp)})`,
                  },
                }))}
              />
            )}
            {isTyping && (
              <div className="typing-text">
                <span className="typing-font">healthChat is typing</span>
                <img
                  src="chat2/gif-typing.gif"
                  alt="Typing indicator"
                  style={{ width: "40px", height: "auto" }}
                />
              </div>
            )}
            {showSuggestedReply && (
              <div style={{ padding: "10px", textAlign: "center" }}>
                <button onClick={handleSuggestedReply} className="suggest-text">
                  {suggestedReplyText}
                </button>
              </div>
            )}
            <MessageInput
              onSendMessage={(text) => handleDefaultMessage(text)}
              placeholder="Login & Talk to healthChat AI"
            />
          </MessageContainer>
        </MainContainer>
      </MinChatUiProvider>
    </>
  );
};

export default TestChat;
