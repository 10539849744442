import React, { useState, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { nanoid } from "nanoid";
import "./MealSnap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCamera,
  faCameraRotate,
  faChevronLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { userState } from "../../atom/atoms";
import Typewriter from "typewriter-effect";
import { addMealItem, mealDB } from "../../store/pouch/meal-pouch";
import { mealAtom } from "../../atom/meal/meal-atom";
import { getLocalDateOnly } from "../../util/Util";

const MealSnap = () => {
  const setMealState = useSetRecoilState(mealAtom);
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [mealType, setMealType] = useState("Breakfast");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowCamera, setIsShowCamera] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const options = ["Breakfast", "Lunch", "Dinner", "Snack", "Supper"];

  const handleBack = () => {
    navigate("/daily");
  };

  const handleGallery = () => {
    navigate("/gallery");
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { exact: "environment" },
        },
      });
      videoRef.current.srcObject = stream;
      setIsShowCamera(true);
    } catch (err) {
      try {
        const fallbackStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoRef.current.srcObject = fallbackStream;
        setIsShowCamera(true);
      } catch (fallbackErr) {
        console.error("Error accessing any camera:", fallbackErr);
        setIsShowCamera(false);
      }
    }
  };

  const toggleCamera = async () => {
    const currentFacingMode = videoRef.current.srcObject
      .getVideoTracks()[0]
      .getSettings().facingMode;
    const newFacingMode = currentFacingMode === "user" ? "environment" : "user";

    const constraints = {
      video: { facingMode: { exact: newFacingMode } },
    };

    try {
      const newStream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = newStream;
    } catch (err) {
      console.error("Error switching camera:", err);
    }
  };

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setIsShowCamera(false);
  };

  const takePicture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, 128, 128);
    canvas.toBlob(async (blob) => {
      const mealId = nanoid(10);
      const reader = new FileReader();

      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const base64Data = reader.result;
        try {
          let dt = new Date();
          let newMealSnappedId = `${user?.userEmail}-meal-${dt.toISOString()}`;
          await addMealItem({
            _id: newMealSnappedId,
            mealId,
            userEmail: user.email,
            mealType,
            mealDate: getLocalDateOnly(),
            mealDateTime: dt.toISOString(),
            mealEpoch: dt.getTime(),
            mealAttachmentB64Array: [base64Data],
            mealIsDeleted: false,
          });
          setMealState((prevState) => ({
            ...prevState,
            mealSnappedId: newMealSnappedId,
          }));
          navigate("/mealcrop");
        } catch (error) {
          console.error("Error saving meal:", error);
        }
      };
      stopCamera();
    }, "image/jpeg");
  };

  return (
    <>
      {!isShowCamera && (
        <>
          <div className="w-full flex justify-between px-4 mb-4">
            <>
              <button
                onClick={handleBack}
                className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
              >
                <FontAwesomeIcon icon={faChevronLeft} size="lg" /> Back
              </button>
              <button
                onClick={handleGallery}
                className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
              >
                <FontAwesomeIcon icon={faCalendar} size="lg" /> Meal History
              </button>
            </>
          </div>

          <div className="flex flex-col justify-center items-center mt-4">
            <img
              src="chat2/05-cute-chatbot.jpg"
              alt="healthChat"
              className="w-16 h-16 rounded-full object-cover"
            />
            <div className="text-center text-xl text-blue-600 ml-2">
              <div>Bon Apetit!</div>
            </div>
            <div className="flex justify-center mt-4">
              <div className="text-center text-xl font-thin text-blue-600">
                <Typewriter
                  options={{
                    strings: [`Snap top view of a ready meal.`],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    cursor: "",
                  }}
                />
              </div>
            </div>
          </div>
          <>
            <div className="flex flex-col justify-center items-center mt-4">
              <button
                className="bg-blue-400 text-white px-4 py-2 rounded"
                onClick={() => setIsModalOpen(true)}
              >
                Select Meal <b>({mealType})</b>
              </button>
            </div>

            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white py-4 px-20 rounded">
                  {options.map((option) => (
                    <React.Fragment key={option}>
                      <button
                        className="mb-3 block w-full rounded-md text-white font-medium text-left bg-blue-400 shadow-lg shadow-blue-800/35 py-2 px-4"
                        onClick={() => {
                          setMealType(option);
                          setIsModalOpen(false);
                        }}
                      >
                        {option}
                      </button>
                    </React.Fragment>
                  ))}
                  <button
                    className="mt-4 bg-gray-500 text-white text-medium px-8 py-2 rounded-md shadow-md shadow-blue-800/35"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </>
          <div className="flex flex-col justify-center items-center mt-4">
            <div className="flex items-center mt-4">
              <div
                className="camera-container bg-blue-400 w-32 h-48 rounded-lg border-2 border-gray-300 flex items-center justify-center cursor-pointer"
                onClick={startCamera}
              >
                <button onClick={startCamera}>
                  <div className="camera-placeholder">
                    {isShowCamera ? null : (
                      <>
                        <div className="shadow-lg shadow-gray-500">
                          <img
                            src="/daily2/10-meal-snap.jpeg"
                            alt="Mealsnap Ready"
                            className=""
                          />
                        </div>
                        <div className="mt-2 mb-2 px-3 text-white font-medium">
                          Click here when ready
                        </div>
                      </>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="flex flex-col justify-center items-center mt-4">
        <video
          ref={videoRef}
          style={{ display: isShowCamera ? "block" : "none" }}
          autoPlay
        />
        <canvas ref={canvasRef} style={{ display: "none" }} className="" />
        {isShowCamera && (
          <>
            <div className="flex  justify-center mt-4">
              <div className="mt-1 flex items-center justify-center bg-blue-400 rounded-full w-28 h-28 shadow-md shadow-blue-800/35">
                <button
                  className="flex items-center justify-center w-full h-full text-white"
                  onClick={takePicture}
                  disabled={!videoRef.current?.srcObject}
                >
                  <FontAwesomeIcon icon={faCamera} size="3x" />
                </button>
              </div>
            </div>
            <div className="flex  justify-center mt-4">
              <div className="mt-1 flex items-center justify-center bg-blue-400 rounded-full w-16 h-16 shadow-md shadow-blue-800/35">
                <button
                  className="flex items-center justify-center w-full h-full text-white"
                  onClick={toggleCamera}
                  disabled={!videoRef.current?.srcObject}
                >
                  <FontAwesomeIcon icon={faCameraRotate} size="2x" />
                </button>
              </div>
              <div className="mt-1 flex items-center justify-center bg-blue-400 rounded-full w-16 h-16 shadow-md shadow-blue-800/35">
                <button
                  className="flex items-center justify-center w-full h-full text-red-600"
                  onClick={stopCamera}
                  disabled={!videoRef.current?.srcObject}
                >
                  <FontAwesomeIcon icon={faTimes} size="3x" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MealSnap;
