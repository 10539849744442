import { atom } from "recoil";
import { localStorageEffect } from "../../util/localStorageEffect";

export const mealAtom = atom({
  key: "mealAtom",
  default: {
    mealSnappedId: null,
    mealGallery: [],
  },
  effects: [localStorageEffect("mealAtom")],
});
