import { atom, selector } from "recoil";

export const coinAtom = atom({
  key: "coinAtom",
  default: {
    coinEarnedAnimation: false,
    coinEarnedAmount: 0,
  },
});

// Selector for coinEarnedAnimation
export const coinEarnedAnimationState = selector({
  key: "coinEarnedAnimationState",
  get: ({ get }) => get(coinAtom).coinEarnedAnimation,
  set: ({ set }, newValue) =>
    set(coinAtom, (prevState) => ({
      ...prevState,
      coinEarnedAnimation: newValue,
    })),
});

// Selector for coinEarnedAmount
export const coinEarnedAmountState = selector({
  key: "coinEarnedAmountState",
  get: ({ get }) => get(coinAtom).coinEarnedAmount,
  set: ({ set }, newValue) =>
    set(coinAtom, (prevState) => ({
      ...prevState,
      coinEarnedAmount: newValue,
    })),
});
